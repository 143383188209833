import { combineReducers } from 'redux'
import { createReducer } from './utility'

// Data structure

const locationInitial = {
  isFetching: false,
  isPartial: false,
  isError: false,
  lastFetched: null,
  didInvalidate: false,
  data: { }
}

const locationIndexInitial = {
  isFetching: false,
  isError: false,
  lastFetched: null,
  path: null,
  data: { }
}

// Actions
const ACTION = {
  fetchLocationIndex: "fetchLocationIndex",
  requestLocationIndex: "requestLocationIndex",
  receiveLocationIndex: "receiveLocationIndex",
  errorRequestLocationIndex: "errorRequestLocationIndex",
  invalidateLocationIndex: "invalidateLocationIndex",
  fetchLocation: "fetchLocation",
  requestLocation: "requestLocation",
  receiveLocation: "receiveLocation",
  errorRequestLocation: "errorRequestLocation",
  invalidateLocation: "invalidateLocation",
}


export const indexKeyFor = (path, params) => {
  let key = path
//  alert(JSON.stringify(params))
  if (params)
    key += Object.keys(params).map(key =>
      "&" + key + "=" + params[key]).join("")

  return key
}

// Async action creator

export const getLocationIndex = (state, path, params) => {
  let key = indexKeyFor(path, params)
  if (key in state.client.location.indexes) {
    return state.client.location.indexes[key]
  }
  
  return locationIndexInitial
}

export const fetchLocationIndex = (path, params=null, refresh=false) => {
  return (dispatch, getState) => {
    let res = getLocationIndex(getState(), path, params)
    if (res.isFetching || (res.lastFetched && !refresh))
      return Promise.resolve()
      
//    if (!path)
//      path = "/"
  
    let url = process.env.REACT_APP_GOAPP_API_URL + "/address/api/state/?country=ID"
      
    if (params)
      url += Object.keys(params).map(key =>
        "&" + key + "=" + params[key]).join("")
        
//    alert("Fetching: " + url)

    let user = getState().alpha.user
        
    dispatch(requestLocationIndex(path, params));
    return fetch(url, {
          headers: { 
            'Content-Type': 'application/json',
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          },
        })
      .then(response => response.json())
      .then(json => dispatch(receiveLocationIndex(path, params, json)))
      .catch(error => dispatch(errorRequestLocationIndex(path, params, error)));
  }
};

export const getLocation = (state, recordKey) => {
//   if (recordKey in state.location.records)
//       return state.location.records[recordKey]

if (state.client.location.records)
      return state.client.location.records
  
  return locationInitial
}


export const fetchLocation = (recordKey, refresh=false, params=null) => {
  return (dispatch, getState) => {
    let res = getLocation(getState(), recordKey)
    if (res.isFetching || (res.lastFetched && !res.didInvalidate && !refresh))
      return Promise.resolve()
  
    let url = process.env.REACT_APP_GOAPP_API_URL + "/address/api/city/?state="+recordKey+"&country=ID"
      
    if (params)
      url += Object.keys(params).map(key =>
        "&" + key + "=" + params[key]).join("")
        
    let user = getState().alpha.user        

    dispatch(requestLocation(recordKey, params)); 
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          },
        })
      .then(response => {
//          alert("fetchTrainer response code: " + response.status.toString())
          if (response.status == 200)
              return response.json()
          else if (response.status == 404)
              throw new Error("Not Found")
          else
              throw new Error(response.json())
      })
      .then(json => dispatch(receiveLocation(recordKey, params, json)))
      .catch(error => dispatch(errorRequestLocation(recordKey, params, error)))
  }
};

// Synchronous Action Creators for Product Index/Listing

export const invalidateLocationIndex = (path, params) => ({
  type: ACTION.invalidateLocationindex,
  path
});

export const requestLocationIndex = (path, params) => ({
  type: ACTION.requestLocationIndex,
  path,
  params
});

export const receiveLocationIndex = (path, params, json) => {

  let result = {
    path: path,
    count: json.length,
    records: json
  }
  
//  alert("receiveTrainerIndex")
//  alert(JSON.stringify(result, null, 2))

  return {
    type: ACTION.receiveLocationIndex,
    path,
    params,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestLocationIndex = (recordKey, params, error) => ({
  type: ACTION.errorRequestLocationIndex,
  recordKey,
  params,
  error
});

export const invalidateLocation = (recordKey) => ({
  type: ACTION.invalidateLocation,
  recordKey
});

export const requestLocation = (recordKey, params) => ({
  type: ACTION.requestLocation,
  recordKey,
  params
});

export const receiveLocation = (recordKey, params, json) => {

  let result = json

  return {
    type: ACTION.receiveLocation,
    recordKey,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestLocation = (recordKey, params, error) => ({
  type: ACTION.errorRequestLocation,
  recordKey,
  params,
  error
});

// Reducers for Product Index

const locationIndexReducer = createReducer(locationIndexInitial, {
  [ACTION.requestLocationIndex]: (state, action) => {
      return {
        ...state,
        isFetching: true
      }
    },
  [ACTION.receiveLocationIndex]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        isPartial: false,
        lastFetched: action.receivedAt,
        data: action.result
      }        
    },
  [ACTION.errorRequestLocationIndex]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
  [ACTION.invalidateLocationIndex]: (state, action) => {
      if (!state.isFetching)
        return {
          ...state,
          lastFetched: null,
        }
      else
        return state
    },    
})

function locationIndexByKeyUpdater(state, action) {
  let key = indexKeyFor(action.path, action.params)
  return {
    ...state,
    [key]: locationIndexReducer(state[key], action)
  }
}

const locationIndexByKeyReducer = createReducer({}, {
  [ACTION.requestLocationIndex]: locationIndexByKeyUpdater,
  [ACTION.receiveLocationIndex]: locationIndexByKeyUpdater,
  [ACTION.errorReceiveLocationIndex]: locationIndexByKeyUpdater,
  [ACTION.invalidateLocationIndex]: locationIndexByKeyUpdater,
})


// Reducers for Product Record

const locationReducer = createReducer(locationInitial, {
  [ACTION.requestLocation]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      }
    },
  [ACTION.receiveLocation]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        isPartial: false,
        didInvalidate: false,
        lastFetched: action.receivedAt,
        data: action.result
      }        
    },
  [ACTION.errorRequestLocation]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
  [ACTION.invalidateLocation]: (state, action) => {
      return {
        ...state,
        didInvalidate: true,
      }
    },    
})

function locationByKeyUpdater(state, action) {
  let key = action.recordKey
  return {
    ...state,
    [key]: locationReducer(state[key], action)
  }
}

const locationByKeyReducer = createReducer({}, {
  [ACTION.requestLocation]: locationByKeyUpdater,
  [ACTION.receiveLocation]: locationByKeyUpdater,
  [ACTION.errorRequestLocation]: locationByKeyUpdater,
  [ACTION.invalidateLocation]: locationByKeyUpdater,
})

// Combine all catalog reducer

const locationDataReducer = combineReducers({
  indexes: locationIndexByKeyReducer,
  records: locationByKeyReducer,
});

export default locationDataReducer