import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/styles'
import theme from './theme'

import './index.css'

import * as serviceWorker from './serviceWorker'

import configureStore from './store/configureStore'

const { store } = configureStore()

const mainApp = process.env.REACT_APP_MAIN || 'client'
//const mainApp = 'admin'

const App = React.lazy(() => import(`./app/${mainApp}/App`));
// const App = React.lazy(() => import(`./app/client/App`));
//const App = React.lazy(() => import(`./app/admin/App`));

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Router>
        <Suspense fallback={<div></div>}>
          <App />
        </Suspense>
      </Router>
    </ThemeProvider>
  </Provider>   
, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
