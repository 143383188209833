import { combineReducers } from 'redux'
import { createReducer } from './utility'

// Data structure

const providerInitial = {
  isFetching: false,
  isPartial: false,
  isError: false,
  lastFetched: null,
  didInvalidate: false,
  data: { }
}

const providerTrainerInitial = {
  isFetching: false,
  isPartial: false,
  isError: false,
  lastFetched: null,
  didInvalidate: false,
  data: { }
}

const providerCourseInitial = {
  isFetching: false,
  isPartial: false,
  isError: false,
  lastFetched: null,
  didInvalidate: false,
  data: { }
}

const providerIndexInitial = {
  isFetching: false,
  isError: false,
  lastFetched: null,
  path: null,
  data: { }
}

// Actions
const ACTION = {
  fetchProviderIndex: "fetchProviderIndex",
  requestProviderIndex: "requestProviderIndex",
  receiveProviderIndex: "receiveProviderIndex",
  errorRequestProviderIndex: "errorRequestProviderIndex",
  invalidateProviderIndex: "invalidateProviderIndex",
  fetchProvider: "fetchProvider",
  requestProvider: "requestProvider",
  receiveProvider: "receiveProvider",
  modifyProvider: "modifyProvider",
  errorRequestProvider: "errorRequestProvider",
  requestFeaturedCourses: "requestFeaturedCourses",
  receiveFeaturedCourses: "receiveFeaturedCourses",
  errorFeaturedCourses: "errorFeaturedCourses",
  invalidateFeaturedCourses: "invalidateFeaturedCourses",
  requestFeaturedTrainers: "requestFeaturedTrainers",
  receiveFeaturedTrainers: "receiveFeaturedTrainers",
  errorFeaturedTrainers: "errorFeaturedTrainers",
  invalidateFeaturedTrainers: "invalidateFeaturedTrainers",
  invalidateProvider: "invalidateProvider",
  requestDownload: "requestDownload"
}


export const indexKeyFor = (path, params) => {
  let key = path
//  alert(JSON.stringify(params))
  if (params)
    key += Object.keys(params).map(key =>
      "&" + key + "=" + params[key]).join("")

  return key
}

// Async action creator

export const getProviderIndex = (state, path, params) => {
  let key = indexKeyFor(path, params)
  if (key in state.admin.provider.indexes) {
    return state.admin.provider.indexes[key]
  }
  
  return providerIndexInitial
}

export const fetchProviderIndex = (path, params=null, refresh=false) => {
  return (dispatch, getState) => {
    let res = getProviderIndex(getState(), path, params)
    if (res.isFetching || (res.lastFetched && !refresh))
      return Promise.resolve()
      
//    if (!path)
//      path = "/"
  
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainer/api/provider/admin/?key_path=" + path
      
    if (params)
      url += Object.keys(params).map(key =>
        "&" + key + "=" + params[key]).join("")
        
//    alert("Fetching: " + url)

    let user = getState().alpha.user
        
    dispatch(requestProviderIndex(path, params));
    return fetch(url, {
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
          },
        })
      .then(response => response.json())
      .then(json => dispatch(receiveProviderIndex(path, params, json)))
      .catch(error => dispatch(errorRequestProviderIndex(path, params, error)));
  }
};

export const getProvider = (state, recordKey) => {
  if (recordKey in state.admin.provider.records)
      return state.admin.provider.records[recordKey]
  
  return providerInitial
}

export const getFeaturedTrainer = (state, recordKey) => {
  if (recordKey in state.admin.provider.trainers)
      return state.admin.provider.trainers[recordKey]
  
  return providerTrainerInitial
}

export const getFeaturedCourse = (state, recordKey) => {
  if (recordKey in state.admin.provider.courses)
      return state.admin.provider.courses[recordKey]
  
  return providerCourseInitial
}


export const fetchProvider = (recordKey, refresh=false, params=null) => {
  return (dispatch, getState) => {
    let res = getProvider(getState(), recordKey)
    if (res.isFetching || (res.lastFetched && !res.didInvalidate))
      return Promise.resolve()
  
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainer/api/provider/admin/" + recordKey + "/"
      
    if (params)
      url += Object.keys(params).map(key =>
        "&" + key + "=" + params[key]).join("")
        
    let user = getState().alpha.user        

    dispatch(requestProvider(recordKey, params)); 
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
          },
        })
      .then(response => {
        return response.json().then(json => {
          if (response.ok)
            dispatch(receiveProvider(recordKey, params, json))
          else
            dispatch({
              type: ACTION.errorRequestProvider,
              recordKey,
              params,
              error: JSON.stringify(json, null, 2)
            })
        })
        .catch(error => dispatch(errorRequestProvider(recordKey, params, error)))
      })
  }
};

export const fetchFeaturedTrainer = (recordKey, refresh=false, params=null) => {
  return (dispatch, getState) => {
    let res = getFeaturedTrainer(getState(), recordKey)
    if (res.isFetching || (res.lastFetched && !res.didInvalidate && !refresh))
      return Promise.resolve()
  
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainer/api/provider/admin/" + recordKey + "/featured_trainers/"
      
    if (params)
      url += Object.keys(params).map(key =>
        "&" + key + "=" + params[key]).join("")
        
    let user = getState().alpha.user        

    dispatch(requestFeaturedTrainers(recordKey, params)); 
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
          },
        })
      .then(response => {
//          alert("fetchTrainer response code: " + response.status.toString())
          if (response.status == 200)
              return response.json()
          else if (response.status == 404)
              throw new Error("Not Found")
          else
              throw new Error(response.json())
      })
      .then(json => dispatch(receiveFeaturedTrainers(recordKey, params, json)))
      .catch(error => dispatch(errorFeaturedTrainers(recordKey, params, error)))
  }
};

export const fetchFeaturedCourse = (recordKey, refresh=false, params=null) => {
  return (dispatch, getState) => {
    let res = getFeaturedCourse(getState(), recordKey)
    if (res.isFetching || (res.lastFetched && !res.didInvalidate && !refresh))
      return Promise.resolve()
  
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainer/api/provider/admin/" + recordKey + "/featured_courses/"
      
    if (params)
      url += Object.keys(params).map(key =>
        "&" + key + "=" + params[key]).join("")
        
    let user = getState().alpha.user        

    dispatch(requestFeaturedCourses(recordKey, params)); 
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
          },
        })
      .then(response => {
//          alert("fetchTrainer response code: " + response.status.toString())
          if (response.status == 200)
              return response.json()
          else if (response.status == 404)
              throw new Error("Not Found")
          else
              throw new Error(response.json())
      })
      .then(json => dispatch(receiveFeaturedCourses(recordKey, params, json)))
      .catch(error => dispatch(errorFeaturedCourses(recordKey, params, error)))
  }
};

export const updateProvider = (key,data, onComplete) => {
  return (dispatch, getState) => {
    let user = getState().alpha.user

    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainer/api/provider/admin/" +key+"/"
    
    // key can be anything unique.
    
    dispatch(modifyProvider(key))
    
    return fetch(url, {
        method: 'PUT',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => {
        dispatch(receiveProvider(key, null, json))
        onComplete()
      })
      .catch(error => dispatch(errorRequestProvider(key, null,error)))
  }
}

export const registrationProvider = (data, onComplete, onError) => {
  return (dispatch, getState) => {
    let user = getState().alpha.user

    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainer/api/provider/registration/"
    
    // key can be anything unique.
    
    // dispatch(modifyProvider(profileKey))
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
        },
        body: JSON.stringify(data)
      })
      .then(response => {
        if (response.ok) {
          Promise.resolve(response.json()).then(function(value) {
            onComplete(value)
          })
        } 
        else {
          Promise.resolve(response.json()).then(function(value) {
            onError()
            alert(value.detail || value[0]) 
          })
        }
      })
      .catch(error => alert(error))
  }
}

export const updateFeaturedCourse = (key,data) => {
  return (dispatch, getState) => {
    let user = getState().alpha.user

    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainer/api/provider/admin/" +key+"/featured_courses/"
    
    // key can be anything unique.
    
    // dispatch(modifyProvider(profileKey))
    
    return fetch(url, {
        method: 'PUT',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => dispatch(receiveFeaturedCourses(key, null, json)))
      .catch(error => dispatch(errorFeaturedCourses(key, null, error)))
  }
}

export const updateFeaturedTrainer = (key,data) => {
  return (dispatch, getState) => {
    let user = getState().alpha.user

    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainer/api/provider/admin/" +key+"/featured_trainers/"
    
    // key can be anything unique.
    
    // dispatch(modifyProvider(profileKey))
    
    return fetch(url, {
        method: 'PUT',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => dispatch(receiveFeaturedTrainers(key, null, json)))
      .catch(error => dispatch(errorFeaturedTrainers(key, null, error)))
  }
}

// Synchronous Action Creators for Product Index/Listing

export const invalidateProviderIndex = (path, params) => ({
  type: ACTION.invalidateProductindex,
  path
});

export const requestProviderIndex = (path, params) => ({
  type: ACTION.requestProviderIndex,
  path,
  params
});

export const receiveProviderIndex = (path, params, json) => {

  let result = {
    path: path,
    count: json.length,
    records: json
  }
  
//  alert("receiveProviderIndex")
//  alert(JSON.stringify(result, null, 2))

  return {
    type: ACTION.receiveProviderIndex,
    path,
    params,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestProviderIndex = (recordKey, params, error) => ({
  type: ACTION.errorRequestProviderIndex,
  recordKey,
  params,
  error
});

export const invalidateProvider = (recordKey) => ({
  type: ACTION.invalidateProvider,
  recordKey
});

export const requestProvider = (recordKey, params) => ({
  type: ACTION.requestProvider,
  recordKey,
  params
});

export const requestFeaturedTrainers = (recordKey, params) => ({
  type: ACTION.requestFeaturedTrainers,
  recordKey,
  params
});

export const requestFeaturedCourses = (recordKey, params) => ({
  type: ACTION.requestFeaturedCourses,
  recordKey,
  params
});

export const requestDownload = (recordKey, params) => ({
  type: ACTION.requestDownload,
  recordKey,
  params
});

export const modifyProvider = (key) => ({
  type: ACTION.modifyProvider,
  key
})

export const invalidateFeaturedCourses = (path, params) => ({
  type: ACTION.invalidateFeaturedCourses,
  path
});

export const invalidateFeaturedTrainers = (key) => ({
  type: ACTION.invalidateFeaturedTrainers,
  key
})

export const receiveProvider = (recordKey, params, json) => {

  let result = json

  return {
    type: ACTION.receiveProvider,
    recordKey,
    result: result,
    receivedAt: Date.now()
  };
}

export const receiveFeaturedTrainers = (recordKey, params, json) => {

  let result = json

  return {
    type: ACTION.receiveFeaturedTrainers,
    recordKey,
    result: result,
    receivedAt: Date.now()
  };
}

export const receiveFeaturedCourses = (recordKey, params, json) => {

  let result = json

  return {
    type: ACTION.receiveFeaturedCourses,
    recordKey,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestProvider = (recordKey, params, error) => ({
  type: ACTION.errorRequestProvider,
  recordKey,
  params,
  error
});

export const errorFeaturedTrainers = (recordKey, params, error) => ({
  type: ACTION.errorFeaturedTrainers,
  recordKey,
  params,
  error
});

export const errorFeaturedCourses = (recordKey, params, error) => ({
  type: ACTION.errorFeaturedCourses,
  recordKey,
  params,
  error
});

// Reducers for Product Index

const providerIndexReducer = createReducer(providerIndexInitial, {
  [ACTION.requestProviderIndex]: (state, action) => {
      return {
        ...state,
        isFetching: true
      }
    },
  [ACTION.receiveProviderIndex]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        isPartial: false,
        lastFetched: action.receivedAt,
        data: action.result
      }        
    },
  [ACTION.errorRequestProviderIndex]: (state, action) => {
      // alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
  [ACTION.invalidateProviderIndex]: (state, action) => {
      if (!state.isFetching)
        return {
          ...state,
          lastFetched: null,
        }
      else
        return state
    },    
})

function providerIndexByKeyUpdater(state, action) {
  let key = indexKeyFor(action.path, action.params)
  return {
    ...state,
    [key]: providerIndexReducer(state[key], action)
  }
}

const providerIndexByKeyReducer = createReducer({}, {
  [ACTION.requestProviderIndex]: providerIndexByKeyUpdater,
  [ACTION.receiveProviderIndex]: providerIndexByKeyUpdater,
  [ACTION.errorReceiveProviderIndex]: providerIndexByKeyUpdater,
  [ACTION.invalidateProviderIndex]: providerIndexByKeyUpdater,
})


// Reducers for Product Record

const providerReducer = createReducer(providerInitial, {
  [ACTION.requestProvider]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      }
    },
    [ACTION.modifyProvider]: (state, action) => {
      return {
        ...state,
        isUpdating: true
      }
    },
  [ACTION.receiveProvider]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        isPartial: false,
        didInvalidate: false,
        lastFetched: action.receivedAt,
        data: action.result
      }        
    },
  [ACTION.errorRequestProvider]: (state, action) => {
      // alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
  [ACTION.invalidateProvider]: (state, action) => {
      return {
        ...state,
        didInvalidate: true,
      }
    },    
})

function providerByKeyUpdater(state, action) {
  let key = action.recordKey
  return {
    ...state,
    [key]: providerReducer(state[key], action)
  }
}

const providerByKeyReducer = createReducer({}, {
  [ACTION.requestProvider]: providerByKeyUpdater,
  [ACTION.receiveProvider]: providerByKeyUpdater,
  [ACTION.errorRequestProvider]: providerByKeyUpdater,
  [ACTION.invalidateProvider]: providerByKeyUpdater,
})

//featured trainers
const featuredTrainersReducer = createReducer(providerTrainerInitial, {
  [ACTION.requestFeaturedTrainers]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      }
    },
  [ACTION.receiveFeaturedTrainers]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        isPartial: false,
        didInvalidate: false,
        lastFetched: action.receivedAt,
        data: action.result
      }        
    },
  [ACTION.errorFeaturedTrainers]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },   
    [ACTION.invalidateFeaturedTrainers]: (state, action) => {
      if (!state.isFetching)
        return {
          ...state,
          lastFetched: null,
        }
      else
        return state
    },  
})

function featuredTrainersByKeyUpdater(state, action) {
  let key = action.recordKey
  return {
    ...state,
    [key]: featuredTrainersReducer(state[key], action)
  }
}

const featuredTrainersByKeyReducer = createReducer({}, {
  [ACTION.requestFeaturedTrainers]: featuredTrainersByKeyUpdater,
  [ACTION.receiveFeaturedTrainers]: featuredTrainersByKeyUpdater,
  [ACTION.errorFeaturedTrainers]: featuredTrainersByKeyUpdater,
})

//featured course
const featuredCoursesReducer = createReducer(providerCourseInitial, {
  [ACTION.requestFeaturedCourses]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      }
    },
  [ACTION.receiveFeaturedCourses]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        isPartial: false,
        didInvalidate: false,
        lastFetched: action.receivedAt,
        data: action.result
      }        
    },
  [ACTION.errorFeaturedCourses]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },  
    [ACTION.invalidateFeaturedCourses]: (state, action) => {
      if (!state.isFetching)
        return {
          ...state,
          lastFetched: null,
        }
      else
        return state
    },  
})

function featuredCoursesByKeyUpdater(state, action) {
  let key = action.recordKey
  return {
    ...state,
    [key]: featuredCoursesReducer(state[key], action)
  }
}

const featuredCoursesByKeyReducer = createReducer({}, {
  [ACTION.requestFeaturedCourses]: featuredCoursesByKeyUpdater,
  [ACTION.receiveFeaturedCourses]: featuredCoursesByKeyUpdater,
  [ACTION.errorFeaturedCourses]: featuredCoursesByKeyUpdater
})

// Combine all catalog reducer

const providerDataReducer = combineReducers({
  indexes: providerIndexByKeyReducer,
  records: providerByKeyReducer,
  trainers: featuredTrainersByKeyReducer,
  courses: featuredCoursesByKeyReducer
});

export default providerDataReducer