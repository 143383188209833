import { combineReducers } from 'redux'
import { createReducer } from './utility'

// Data structure

const providerInitial = {
  isFetching: false,
  isPartial: false,
  isError: false,
  lastFetched: null,
  didInvalidate: false,
  data: { }
}

const providerIndexInitial = {
  isFetching: false,
  isError: false,
  lastFetched: null,
  path: null,
  data: { }
}

// Actions
const ACTION = {
  fetchProviderIndex: "fetchProviderIndex",
  requestProviderIndex: "requestProviderIndex",
  receiveProviderIndex: "receiveProviderIndex",
  errorRequestProviderIndex: "errorRequestProviderIndex",
  invalidateProviderIndex: "invalidateProviderIndex",
  fetchProvider: "fetchProvider",
  requestProvider: "requestProvider",
  receiveProvider: "receiveProvider",
  errorRequestProvider: "errorRequestProvider",
  invalidateProvider: "invalidateProvider",
  requestDownload: "requestDownload"
}


export const indexKeyFor = (path, params) => {
  let key = path
  return key
}

// Async action creator

export const getProviderIndex = (state, path, params) => {
  if (path in state.client.provider.indexes) {
    return state.client.provider.indexes[path]
  }
  
  return providerIndexInitial
}

export const fetchProviderIndex = (path, params = null, refresh = false, fetchMore = false) => {
  return (dispatch, getState) => {
    let res = getProviderIndex(getState(), path, params)
    if (res.isFetching || (res.lastFetched && !refresh))
      return Promise.resolve()
      
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainer/api/provider/search/?key_path=" + path + "&image_size=m"
      
    if (params)
      url += Object.keys(params).map(key =>
        "&" + key + "=" + params[key]).join("")
        
    let user = getState().alpha.user
    let authHeader = {}
    if (user.isLoggedIn) {
      authHeader['Authorization'] = 'jwt ' + user.authToken
      if (user.companyInfo)
        authHeader['X-Company-ID'] = user.companyInfo.uid.toString()
    }
    
    dispatch(requestProviderIndex(path, params));
    return fetch(url, {
          headers: { 
            'Content-Type': 'application/json',
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            ...authHeader
          },
        })
      .then(response => response.json())
      .then(json => {
        if (fetchMore) {
          let res = getProviderIndex(getState(), path, null)
          let newItems = res.data.records.concat(json.records)

          let newData = {
            total: json.total,
            categories: json.categories,
            offset: json.offset,
            records: newItems
          }

          dispatch(receiveProviderIndex(path, params, newData))
        } else {
          dispatch(receiveProviderIndex(path, params, json))
        }
      })
      .catch(error => dispatch(errorRequestProviderIndex(path, params, error)));
  }
};

export const getProvider = (state, recordKey) => {
  if (recordKey in state.client.provider.records)
      return state.client.provider.records[recordKey]
  
  return providerInitial
}


export const fetchProvider = (recordKey, refresh=false, params=null) => {
  return (dispatch, getState) => {
    let res = getProvider(getState(), recordKey)
    if (res.isFetching || (res.lastFetched && !res.didInvalidate && !refresh))
      return Promise.resolve()
  
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainer/api/provider/" + recordKey + "/"
      
    if (params)
      url += Object.keys(params).map(key =>
        "&" + key + "=" + params[key]).join("")
        
    let user = getState().alpha.user        
    let authHeader = {}
    if (user.isLoggedIn) {
      authHeader['Authorization'] = 'jwt ' + user.authToken
      if (user.companyInfo)
        authHeader['X-Company-ID'] = user.companyInfo.uid.toString()
    }
    
    dispatch(requestProvider(recordKey, params)); 
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            ...authHeader
          },
        })
      .then(response => {
//          alert("fetchTrainer response code: " + response.status.toString())
          if (response.status == 200)
              return response.json()
          else if (response.status == 404)
              throw new Error("Not Found")
          else
              throw new Error(response.json())
      })
      .then(json => dispatch(receiveProvider(recordKey, params, json)))
      .catch(error => dispatch(errorRequestProvider(recordKey, params, error)))
  }
};

export const downloadTrainerProfile = (trainerId, refresh=false, params=null) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainer/api/trainer/" + trainerId + "/download/?document_type=trainer_profile"
        
    let user = getState().alpha.user        
    let authHeader = {}
    if (user.isLoggedIn) {
      authHeader['Authorization'] = 'jwt ' + user.authToken
      if (user.companyInfo)
        authHeader['X-Company-ID'] = user.companyInfo.uid.toString()
    }
    
    dispatch(requestDownload(trainerId, params)); 
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'Accept' : 'application/json',
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            ...authHeader
          },
        })
      .then(response => {
//          alert("fetchTrainer response code: " + response.status.toString())
          if (response.status == 200){
            response.blob().then(function(myBlob) {
              var file = new Blob([myBlob], {type: 'application/pdf'})
              var fileURL = URL.createObjectURL(file)
              window.open(fileURL)
            });
          }
          else if (response.status == 404)
              throw new Error("Not Found")
          else
              throw new Error(response.json())
      })
      // .then(json => {
        
      //   }
      // )
      // .catch(error => dispatch(errorRequestTrainer(trainingClassKey, params, error)))
  }
};

// Synchronous Action Creators for Product Index/Listing

export const invalidateProviderIndex = (path, params) => ({
  type: ACTION.invalidateProductindex,
  path
});

export const requestProviderIndex = (path, params) => ({
  type: ACTION.requestProviderIndex,
  path,
  params
});

export const receiveProviderIndex = (path, params, json) => {

  let result = {
    path: path,
    count: json.total || json['records'].length,
    records: json['records']
  }
  
//  alert("receiveProviderIndex")
//  alert(JSON.stringify(result, null, 2))

  return {
    type: ACTION.receiveProviderIndex,
    path,
    params,
    result,
    receivedAt: Date.now()
  };
}

export const errorRequestProviderIndex = (recordKey, params, error) => ({
  type: ACTION.errorRequestProviderIndex,
  recordKey,
  params,
  error
});

export const invalidateProvider = (recordKey) => ({
  type: ACTION.invalidateProvider,
  recordKey
});

export const requestProvider = (recordKey, params) => ({
  type: ACTION.requestProvider,
  recordKey,
  params
});

export const requestDownload = (recordKey, params) => ({
  type: ACTION.requestDownload,
  recordKey,
  params
});

export const receiveProvider = (recordKey, params, json) => {

  let result = json

  return {
    type: ACTION.receiveProvider,
    recordKey,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestProvider = (recordKey, params, error) => ({
  type: ACTION.errorRequestProvider,
  recordKey,
  params,
  error
});

// Reducers for Product Index

const providerIndexReducer = createReducer(providerIndexInitial, {
  [ACTION.requestProviderIndex]: (state, action) => {
      return {
        ...state,
        isFetching: true
      }
    },
  [ACTION.receiveProviderIndex]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        isPartial: false,
        lastFetched: action.receivedAt,
        data: action.result
      }        
    },
  [ACTION.errorRequestProviderIndex]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
  [ACTION.invalidateProviderIndex]: (state, action) => {
      if (!state.isFetching)
        return {
          ...state,
          lastFetched: null,
        }
      else
        return state
    },    
})

function providerIndexByKeyUpdater(state, action) {
  let key = indexKeyFor(action.path, action.params)
  return {
    ...state,
    [key]: providerIndexReducer(state[key], action)
  }
}

const providerIndexByKeyReducer = createReducer({}, {
  [ACTION.requestProviderIndex]: providerIndexByKeyUpdater,
  [ACTION.receiveProviderIndex]: providerIndexByKeyUpdater,
  [ACTION.errorReceiveProviderIndex]: providerIndexByKeyUpdater,
  [ACTION.invalidateProviderIndex]: providerIndexByKeyUpdater,
})


// Reducers for Product Record

const providerReducer = createReducer(providerInitial, {
  [ACTION.requestProvider]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      }
    },
  [ACTION.receiveProvider]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        isPartial: false,
        didInvalidate: false,
        lastFetched: action.receivedAt,
        data: action.result
      }        
    },
  [ACTION.errorRequestProvider]: (state, action) => {
      // alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
  [ACTION.invalidateProvider]: (state, action) => {
      return {
        ...state,
        didInvalidate: true,
      }
    },    
})

function providerByKeyUpdater(state, action) {
  let key = action.recordKey
  return {
    ...state,
    [key]: providerReducer(state[key], action)
  }
}

const providerByKeyReducer = createReducer({}, {
  [ACTION.requestProvider]: providerByKeyUpdater,
  [ACTION.receiveProvider]: providerByKeyUpdater,
  [ACTION.errorRequestProvider]: providerByKeyUpdater,
  [ACTION.invalidateProvider]: providerByKeyUpdater,
})

// Combine all catalog reducer

const providerDataReducer = combineReducers({
  indexes: providerIndexByKeyReducer,
  records: providerByKeyReducer,
});

export default providerDataReducer