import { combineReducers } from 'redux'

import user from './reducers/user'
import visitor from './reducers/visitor'
import business from './reducers/business'
import content from './reducers/content'
import conversation from './reducers/conversation'

export const rootKey = 'alpha'

export const reducer = combineReducers({
  business,
  user,
  visitor,
  conversation,
  content
})
