import { createStore, applyMiddleware, compose } from 'redux'

import thunk from 'redux-thunk'

import { rootReducer } from './reducer'
import conversationMiddleware from './middleware/conversation'

const enhancers = []

const middleware = [thunk, conversationMiddleware]

if (process.env.NODE_ENV === 'development') {
 middleware.push(require('redux-logger').default)
}
  
const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
)

export default () => {
  const store = createStore(rootReducer, {}, composedEnhancers)
  return { store }
}