import { combineReducers } from 'redux'
import { createReducer } from './utility'

// Data structure

const publicClassInitial = {
  key: null,
  isUpdating: false,
  lastUpdate: null,  
  isFetching: false,
  lastFetched: null,
  isError: false,
  data: { status: null },
}

const trainerInitial = {
  isFetching: false,
  isPartial: false,
  isError: false,
  lastFetched: null,
  didInvalidate: false,
  data: { }
}


const publicClassIndexInitial = {
  isFetching: false,
  isError: false,
  lastFetched: null,
  path: null,
  data: {}
}

const publicClassListInitial = {
  isFetching: false,
  lastFetched: null,
  isError: false,
  data: {
    count: 0,
    records: [ ],
  }
}

const relatedPublicClassListInitial = {
  isFetching: false,
  lastFetched: null,
  isError: false,
  data: {
    count: 0,
    records: [ ],
  }
}

const paymentMethodInitial = {
  key: null,
  isUpdating: false,
  lastUpdate: null,  
  isFetching: false,
  lastFetched: null,
  isError: false,
  data: { status: null },
}

// Actions
const ACTION = {
  requestPublicClassList: "requestPublicClassList",
  receivePublicClassList: "receivePublicClassList",
  errorRequestPublicClassList: "errorRequestPublicClassList",

  fetchPublicClassIndex: "fetchPublicClassIndex",
  requestPublicClassIndex: "requestPublicClassIndex",
  receivePublicClassIndex: "receivePublicClassIndex",
  errorRequestPublicClassIndex: "errorRequestPublicClassIndex",
  invalidatePublicClassIndex: "invalidatePublicClassIndex",

  requestRelatedPublicClassList: "requestRelatedPublicClassList",
  receiveRelatedPublicClassList: "receiveRelatedPublicClassList",
  errorRequestRelatedPublicClassList: "errorRequestRelatedPublicClassList",
  
  requestPublicClass: "requestPublicClass",
  receivePublicClass: "receivePublicClass",
  modifyPublicClass: "modifyPublicClass",
  removePublicClass: "removePublicClass",
  errorModifyPublicClass: "errorModifyPublicClass",
  errorRequestPublicClass: "errorRequestPublicClass",

  requestPaymentMethod: "requestPaymentMethod",
  receivePaymentMethod: "receivePaymentMethod",
  errorRequestPaymentMethod: "errorRequestPaymentMethod"
}

export const getPublicClassList = (state) => {
  return state.client.publicClass.list
}

export const getRelatedPublicClassList = (state) => {
  return state.client.publicClass.relatedPublicClass
}

export const getPublicClass = (state, key) => {
  if (key in state.client.publicClass.records)
    return state.client.publicClass.records[key]
  return null
}

export const getPaymentMethod = (state, key) => {
  if (key in state.client.publicClass.paymentMethods)
      return state.client.publicClass.paymentMethods[key]
  
  return paymentMethodInitial
}

export const errorRequestTrainer = (recordKey, params, error) => ({
  type: ACTION.errorRequestTrainer,
  recordKey,
  params,
  error
});

export const errorRequestPublicClassIndex = (path, params, error) => ({
  type: ACTION.errorRequestPublicClassIndex,
  path,
  params,
  error
});

export const getPublicClassIndex = (state, path, params) => {
  if (path in state.client.publicClass.indexes) {
    return state.client.publicClass.indexes[path]
  }

  return publicClassIndexInitial
}


// Async action creator

export const fetchPublicClassIndex = (path, params = null, refresh = false, fetchMore = false) => {
  return (dispatch, getState) => {
    let res = getPublicClassIndex(getState(), path, params)
    if (res.isFetching || (res.lastFetched && !refresh && !fetchMore))
      return Promise.resolve()

    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/trainingclass/search/?key_path=" + path + "&image_size=m"

    if (params)
      url += Object.keys(params).map(key =>
        "&" + key + "=" + params[key]).join("")

    let user = getState().alpha.user
    let authHeader = {}
    if (user.isLoggedIn) {
      authHeader['Authorization'] = 'jwt ' + user.authToken
      if (user.companyInfo)
        authHeader['X-Company-ID'] = user.companyInfo.uid.toString()
    }

    dispatch(requestPublicClassIndex(path, params));
    return fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
        ...authHeader
      },
    })
      .then(response => response.json())
      .then(json => {
        if (fetchMore) {
          let res = getPublicClassIndex(getState(), path, null)
          let newItems = res.data.records.concat(json.records)

          let newData = {
            total: json.total,
            categories: json.categories,
            offset: json.offset,
            records: newItems
          }

          dispatch(receivePublicClassIndex(path, params, newData))
        } else {
          dispatch(receivePublicClassIndex(path, params, json))
        }
      })
      .catch(error => dispatch(errorRequestPublicClassIndex(path, params, error)));
  }
};

export const fetchPublicClassList = (path, refresh = false, params = null, fetchMore = false) => {
  return (dispatch, getState) => {
    let res = getPublicClassList(getState())
    
    if (res.isFetching || (res.lastFetched && !refresh))
      return Promise.resolve()
      
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/trainingclass/?key_path=" + path

    if(params)
      url += Object.keys(params).map(key =>
        "&" + key + "=" + params[key]).join("")
  

    let user = getState().alpha.user
    let authHeader = {}
    if (user.isLoggedIn) {
      authHeader['Authorization'] = 'jwt ' + user.authToken
      if (user.companyInfo)
        authHeader['X-Company-ID'] = user.companyInfo.uid.toString()
    }    

    dispatch(requestPublicClassList())
    
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            ...authHeader
          },
        })
      .then(response => response.json())
      .then(json => dispatch(receivePublicClassList(json)))
      .catch(error => dispatch(errorRequestPublicClassList(error)))
  }
}

export const fetchRelatedPublicClassList = (refresh=false, params = null) => {
  return (dispatch, getState) => {
    let res = getRelatedPublicClassList(getState())
    
    if (res.isFetching || (res.lastFetched && !refresh))
      return Promise.resolve()
      
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/trainingclass/"

    if(params)
      url += Object.keys(params).map(key =>
        "?" + key + "=" + params[key]).join("")
  

    let user = getState().alpha.user
    let authHeader = {}
    if (user.isLoggedIn) {
      authHeader['Authorization'] = 'jwt ' + user.authToken
      if (user.companyInfo)
        authHeader['X-Company-ID'] = user.companyInfo.uid.toString()
    }    

    dispatch(requestRelatedPublicClassList())
    
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            ...authHeader
          },
        })
      .then(response => response.json())
      .then(json => dispatch(receiveRelatedPublicClassList(json)))
      .catch(error => dispatch(errorRequestRelatedPublicClassList(error)))
  }
}

export const fetchPaymentMethod = (trainingClassKey, refresh=false) => {
  return (dispatch, getState) => {
    let res = getPaymentMethod(getState(), trainingClassKey)
  
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/trainingclass/" + trainingClassKey + "/payment-methods/"    

    let user = getState().alpha.user  
    let authHeader = {}
    if (user.isLoggedIn) {
      authHeader['Authorization'] = 'jwt ' + user.authToken
      if (user.companyInfo)
        authHeader['X-Company-ID'] = user.companyInfo.uid.toString()
    }
    
    dispatch(requestPaymentMethod(trainingClassKey)); 
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            ...authHeader
          },
        })
      .then(response => response.json())
      .then(json => dispatch(receivePaymentMethod(trainingClassKey, json)))
      .catch(error => dispatch(errorRequestPaymentMethod(trainingClassKey, error)));
  }
};

export const fetchPublicClass = (key, refresh=false) => {
  return (dispatch, getState) => {
    let res = getPublicClass(getState(), key)
    // if (res && (res.isFetching || (res.lastFetched  && !refresh)))
    //   return Promise.resolve()
      
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/trainingclass/" + key + "/"
      
    let user = getState().alpha.user
    let authHeader = {}
    if (user.isLoggedIn) {
      authHeader['Authorization'] = 'jwt ' + user.authToken
      if (user.companyInfo)
        authHeader['X-Company-ID'] = user.companyInfo.uid.toString()
    }
    
    dispatch(requestPublicClass(key)); 
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            ...authHeader
          },
        })
      .then(response => response.json())
      .then(json => dispatch(receivePublicClass(key, json)))
      .catch(error => dispatch(errorRequestPublicClass(key, error)));
  }
}

export const purchaseTicket = (key, data, onComplete, onError) => {
  return (dispatch, getState) => {
  let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/trainingclass/" + key + "/purchase_ticket/"  

  let user = getState().alpha.user
    let authHeader = {}
    if (user.isLoggedIn) {
      authHeader['Authorization'] = 'jwt ' + user.authToken
      if (user.companyInfo)
        authHeader['X-Company-ID'] = user.companyInfo ? user.companyInfo.uid.toString() : ""
    }
  
  return fetch(url, {
      method: 'POST',
      cache: 'no-cache',
      headers: { 
        'Content-Type': 'application/json',
        'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
        ...authHeader
      },
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(json => {
      if (!json.order_no) {
        if (json.pay_url) {
          window.open(json.pay_url, "_self")
        }
        else {
          onError()
          alert(json.detail)
        }
      } else{
        onComplete()
      }
    })
    .catch(error => error) 
  }
}


// Synchronous Action Creators


export const requestPublicClassIndex = (path, params) => ({
  type: ACTION.requestPublicClassIndex,
  path,
  params
});


export const receivePublicClassIndex = (path, params, json) => {

  let result = {
    path: path,
    count: json.total || json['records'].length,
    records: json['records'],
    categories: json['categories']
  }


  return {
    type: ACTION.receivePublicClassIndex,
    path,
    result,
    receivedAt: Date.now()
  };
}

export const requestPublicClassList = () => ({
  type: ACTION.requestPublicClassList,
})

export const receivePublicClassList = (json) => {

  let result = {
    count: json.length,
    records: json
  }

//  alert("PublicClassList: " + JSON.stringify(result, null, 2))

  return {
    type: ACTION.receivePublicClassList,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestPublicClassList = (error) => ({
  type: ACTION.errorRequestPublicClassList,
  error
})

export const requestRelatedPublicClassList = () => ({
  type: ACTION.requestRelatedPublicClassList,
})

export const receiveRelatedPublicClassList = (json) => {

  let result = {
    count: json.length,
    records: json
  }

//  alert("PublicClassList: " + JSON.stringify(result, null, 2))

  return {
    type: ACTION.receiveRelatedPublicClassList,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestRelatedPublicClassList = (error) => ({
  type: ACTION.errorRequestRelatedPublicClassList,
  error
})

export const modifyPublicClass = (key) => ({
  type: ACTION.modifyPublicClass,
  key
})

export const requestPublicClass = (key) => ({
  type: ACTION.requestPublicClass,
  key
})

export const receivePublicClass = (key, json) => {

  let result = json
  
//  alert("PublicClass Record:" + JSON.stringify(json, null, 2))

  return {
    type: ACTION.receivePublicClass,
    key,
    result: result,
    receivedAt: Date.now()
  }
}

export const removePublicClass = (key) => ({
  type: ACTION.removePublicClass,
  key
})

export const errorModifyPublicClass = (key, error) => ({
  type: ACTION.errorModifyPublicClass,
  key,
  error
})

export const errorRequestPublicClass = (key, error) => ({
  type: ACTION.errorRequestPublicClass,
  key,
  error
})

//payment
export const requestPaymentMethod = (key) => ({
  type: ACTION.requestPaymentMethod,
  key
})

export const receivePaymentMethod = (key, json) => {

  let result = json
  
//  alert("TrainingClass Record:" + JSON.stringify(json, null, 2))

  return {
    type: ACTION.receivePaymentMethod,
    key,
    result: result,
    receivedAt: Date.now()
  }
}

export const errorRequestPaymentMethod = (key, error) => ({
  type: ACTION.errorRequestPaymentMethod,
  key,
  error
})

// Reducers for Conversation

const publicClassReducer = createReducer(publicClassInitial, {
  [ACTION.requestPublicClass]: (state, action) => {
      return {
        ...state,
        isFetching: true
      }
    },
  [ACTION.modifyPublicClass]: (state, action) => {
      return {
        ...state,
        isUpdating: true
      }
    },
  [ACTION.receivePublicClass]: (state, action) => {
  
//      alert(JSON.stringify(action.result, null, 2))

      return {
        ...state,
        isFetching: false,
        lastFetched: action.receivedAt,
        isUpdating: false,
        lastUpdated: action.receivedAt,
        data: action.result
      }
    },
  [ACTION.errorRequestPublicClass]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
  [ACTION.errorModifyPublicClass]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isUpdating: false,
        isError: true
      }
    },
})

const paymentMethodReducer = createReducer(paymentMethodInitial, {
  [ACTION.requestPaymentMethod]: (state, action) => {
      return {
        ...state,
        isFetching: true
      }
    },
  [ACTION.modifyPaymentMethod]: (state, action) => {
      return {
        ...state,
        isUpdating: true
      }
    },
  [ACTION.receivePaymentMethod]: (state, action) => {
  
//      alert(JSON.stringify(action.result, null, 2))

      return {
        ...state,
        isFetching: false,
        lastFetched: action.receivedAt,
        isUpdating: false,
        lastUpdated: action.receivedAt,
        data: action.result
      }
    },
  [ACTION.errorRequestPaymentMethod]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
})

const publicClassListReducer = createReducer(publicClassListInitial, {
  [ACTION.requestPublicClassList]: (state, action) => {
      return {  
        ...state,
        isFetching: true
      }
    },
  [ACTION.receivePublicClassList]: (state, action) => {
  
      return {
        ...state,
        isFetching: false,
        data: action.result,
        lastFetched: action.receivedAt,
      }        
    },
  [ACTION.errorRequestPublicClassList]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
})


// Reducers for Product Index

const publicClassIndexReducer = createReducer(publicClassIndexInitial, {
  [ACTION.requestPublicClassIndex]: (state, action) => {
    return {
      ...state,
      isFetching: true
    }
  },
  [ACTION.receivePublicClassIndex]: (state, action) => {
    return {
      ...state,
      isFetching: false,
      isPartial: false,
      lastFetched: action.receivedAt,
      data: action.result
    }
  },
  [ACTION.errorRequestCourseIndex]: (state, action) => {
    // alert(action.error)
    return {
      ...state,
      isFetching: false,
      isError: true
    }
  },
  [ACTION.invalidateCourseIndex]: (state, action) => {
    if (!state.isFetching)
      return {
        ...state,
        lastFetched: null,
      }
    else
      return state
  },
})

function publicCourseIndexByKeyUpdater(state, action) {
  return {
    ...state,
    [action.path]: publicClassIndexReducer(state[action.path], action)
  }
}

const publicClassIndexByKeyReducer = createReducer({}, {
  [ACTION.requestPublicClassIndex]: publicCourseIndexByKeyUpdater,
  [ACTION.receivePublicClassIndex]: publicCourseIndexByKeyUpdater,
  [ACTION.errorRequestCourseIndex]: publicCourseIndexByKeyUpdater,
  [ACTION.invalidateCourseIndex]: publicCourseIndexByKeyUpdater,
})

const relatedPublicClassListReducer = createReducer(relatedPublicClassListInitial, {
  [ACTION.requestRelatedPublicClassList]: (state, action) => {
      return {  
        ...state,
        isFetching: true
      }
    },
  [ACTION.receiveRelatedPublicClassList]: (state, action) => {
  
      return {
        ...state,
        isFetching: false,
        data: action.result,
        lastFetched: action.receivedAt,
      }        
    },
  [ACTION.errorRelatedRequestPublicClassList]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
})

function publicClassByKeyUpdater(state, action) {
  let key = action.key
  
  if (action.type == ACTION.removePublicClass) {
    let newState = { ...state }
    if (key in newState)
      delete newState[key]
    return newState
  }  
  
  return {
    ...state,
    [key]: publicClassReducer(state[key], action)
  }
}

function paymentMethodByKeyUpdater(state, action) {
  let key = action.key
  
  if (action.type == ACTION.removeTrainingClass) {
    let newState = { ...state }
    if (key in newState)
      delete newState[key]
    return newState
  }  
  
  return {
    ...state,
    [key]: paymentMethodReducer(state[key], action)
  }
}

const publicClassByKeyReducer = createReducer({}, {
  [ACTION.requestPublicClass]: publicClassByKeyUpdater,
  [ACTION.modifyPublicClass]: publicClassByKeyUpdater,
  [ACTION.removePublicClass]: publicClassByKeyUpdater,
  [ACTION.receivePublicClass]: publicClassByKeyUpdater,
  [ACTION.errorRequestPublicClass]: publicClassByKeyUpdater,
  [ACTION.errorModifyPublicClass]: publicClassByKeyUpdater,
})

const publicClassPaymentByKeyReducer = createReducer({}, {
  [ACTION.requestPaymentMethod]: paymentMethodByKeyUpdater,
  [ACTION.modifyPaymentMethod]: paymentMethodByKeyUpdater,
  [ACTION.receivePaymentMethod]: paymentMethodByKeyUpdater,
  [ACTION.errorRequestPaymentMethod]: paymentMethodByKeyUpdater
})

// Combine list and records reducer

const publicClassDataReducer = combineReducers({
  indexes: publicClassIndexByKeyReducer,
  list: publicClassListReducer,
  records: publicClassByKeyReducer,
  paymentMethods: publicClassPaymentByKeyReducer,
  relatedPublicClass: relatedPublicClassListReducer
});

export default publicClassDataReducer