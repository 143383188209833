import { receiveNewMessage, receiveConversation, receiveConversationMessagesUpdate, wsStateChanged, WSSTATE } from '../alpha/reducers/conversation'

// Actions

export const wsConnect = host => ({ type: 'WS_CONNECT', host });
export const wsConnecting = host => ({ type: 'WS_CONNECTING', host });
export const wsConnected = host => ({ type: 'WS_CONNECTED', host });
export const wsDisconnect = host => ({ type: 'WS_DISCONNECT', host });
export const wsDisconnected = (host) => ({ type: 'WS_DISCONNECTED', host });
export const wsSend = (command, data) => ({ type: 'WS_SEND', command, data });

export const conversationMiddleware = () => {
  let socket = null

	return (store) => (next) => (action) => {
    try {
			switch (action.type) {
			case 'WS_CONNECT':
				if (socket === null) {
					console.log('[websocket] connecting...', socket, socket !== null, action)
					socket = new WebSocket(action.host)
          socket.onmessage = (event) => {
						const payload = JSON.parse(event.data)

						switch (payload.type) {
						case 'new_message':
							store.dispatch(receiveNewMessage(payload.data.conversation.uid, payload.data))
							store.dispatch(receiveConversationMessagesUpdate(payload.data.conversation.uid, payload.data))
							break
						case 'update_conversation':
							store.dispatch(receiveConversation(payload.data.uid, payload.data));
							break
						default:
							break
						}
					}

					socket.onclose = (event) => {
						console.log('[websocket] connection close !', event)
						store.dispatch(wsDisconnected());
					}

					socket.onopen = (event) => {
						console.log('[websocket] connection open !', event);
						store.dispatch(wsConnecting())
            store.dispatch(wsConnected(event.target.url));
					}
					socket.onerror = (event) => {
						console.log('[websocket] connection Error !', event);
						store.dispatch(wsDisconnected());
					}
				}
				break
			case 'WS_START':
				console.log('ws start')
				// Send start command
				break
			case 'WS_CONNECTED':
				store.dispatch(wsStateChanged(WSSTATE.Connected))
				break
			case 'WS_CONNECTING':
				store.dispatch(wsStateChanged(WSSTATE.Connecting))
				break
			case 'WS_DISCONNECTED':
        store.dispatch(wsStateChanged(WSSTATE.Disconnected))
        socket = null          
        break;

			case 'WS_SEND':
				if (socket == null) break
				console.log('sending a message: ', action.command)
				// socket.send(JSON.stringify({ command: action.command, data: action.data }))
				break

			default:
				// console.log('the next action:', action)
				return next(action)
			}
		} catch (error) {
			console.log('some error ini socket', error)
		}
	}
};

export default conversationMiddleware();