import { combineReducers } from 'redux'

import { 
  rootKey as alpha, 
  reducer as alphaReducer 
} from './alpha'

import { 
  rootKey as admin, 
  reducer as adminReducer 
} from './admin'

import { 
  rootKey as client, 
  reducer as clientReducer 
} from './client'

export const rootReducer = combineReducers({
  [alpha]: alphaReducer,
  [client]: clientReducer,
  [admin]: adminReducer
})
