import { createMuiTheme } from '@material-ui/core/styles';
import { green, grey, red } from '@material-ui/core/colors';

const rawTheme = createMuiTheme({
});

const sectionPalette = process.env.REACT_APP_SECTION_PALETTE ? JSON.parse(process.env.REACT_APP_SECTION_PALETTE) : {}

const theme = {
  ...rawTheme,
  palette: {
    ...rawTheme.palette,
    primary: {
      // ...rawTheme.palette.primary,
      main: 'rgb(40, 109, 204)',
    },
    secondary: {
      // ...rawTheme.palette.primary,
      main: 'rgb(255, 140, 0)',
    }    
  },
  sectionPalette:{
    ...rawTheme.sectionPalette,
    white: 'white' in sectionPalette ? { background: sectionPalette.white.background, contrastText: sectionPalette.white.text } : rawTheme.palette.primary,
    black: 'black' in sectionPalette ? { background: sectionPalette.black.background, contrastText: sectionPalette.black.text } : rawTheme.palette.primary,
    light: 'light' in sectionPalette ? { background: sectionPalette.light.background, contrastText: sectionPalette.light.text } : rawTheme.palette.primary,
    dark: 'dark' in sectionPalette ? { background: sectionPalette.dark.background, contrastText: sectionPalette.dark.text } : rawTheme.palette.primary,
    accent: 'accent' in sectionPalette ? { background: sectionPalette.accent.background, contrastText: sectionPalette.accent.text } : rawTheme.palette.secondary,
  },
  typography: {
    ...rawTheme.typography,
    h1: {
      ...rawTheme.typography.h1,
      [rawTheme.breakpoints.down('sm')]: {
        fontSize: '5rem' //'6rem'
      }
    },
    h2: {
      ...rawTheme.typography.h2,
      [rawTheme.breakpoints.down('sm')]: {
        fontSize: '3rem' //'3.75rem'
      }
    },
    h3: {
      ...rawTheme.typography.h3,
      [rawTheme.breakpoints.down('sm')]: {
        fontSize: '2.5rem' //'3rem'
      }
    },
    h4: {
      ...rawTheme.typography.h4,
      [rawTheme.breakpoints.down('sm')]: {
        fontSize: '1.75rem' //'2.125rem'
      }
    },
    h5: {
      ...rawTheme.typography.h5,
      [rawTheme.breakpoints.down('sm')]: {
        fontSize: '1.25rem' // '1.5rem'
      }
    },
    h6: {
      ...rawTheme.typography.h6,
      [rawTheme.breakpoints.down('sm')]: {
        fontSize: '1.125rem' // '1.25rem'
      }
    },
  },
};

export default theme;
