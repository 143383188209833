import { combineReducers } from 'redux'
import course from './reducers/course'
import trainer from './reducers/trainer'
import provider from './reducers/provider'
import proposal from './reducers/proposal'
import trainingClass from './reducers/training_class'
import publicClass from './reducers/public_class'
import location from './reducers/location'
import contact from './reducers/contact'
import dashboard from './reducers/dashboard'
import project from './reducers/project'
import publicOrder from './reducers/public_order'

export const rootKey = 'client'

export const reducer = combineReducers({
  course,
  trainer,
  provider,
  proposal,
  trainingClass,
  publicClass,
  location,
  contact,
  dashboard,
  project,
  publicOrder
})
