import { combineReducers } from 'redux'
import { createReducer } from './utility'

// Data structure

const projectInitial = {
  key: null,
  isUpdating: false,
  lastUpdate: null,  
  isFetching: false,
  lastFetched: null,
  isError: false,
  data: { },
}

const projectChatInitial = {
  key: null,
  isUpdating: false,
  lastUpdate: null,  
  isFetching: false,
  lastFetched: null,
  isError: false,
  data: { },
}

const projectListInitial = {
  isFetching: false,
  lastFetched: null,
  isError: false,
  data: {
    count: 0,
    records: [ ],
  }
}

// Actions
const ACTION = {
  requestProjectList: "requestProjectList",
  receiveProjectList: "receiveProjectList",
  errorRequestProjectList: "errorRequestProjectList",
  
  requestProject: "requestProject",
  receiveProject: "receiveProject",
  modifyProject: "modifyProject",
  removeProject: "removeProject",
  errorModifyProject: "errorModifyProject",
  errorRequestProject: "errorRequestProject",

  requestChatProject: "requestChatProject",
  receiveChatProject: "receiveChatProject",
  removeChatProject: "removeChatProject",
  errorRequestChatProject: "errorRequestChatProject",
}

export const getProjectList = (state) => {
  return state.admin.project.list
}

export const getProject = (state, key) => {
  if (key in state.admin.project.records)
    return state.admin.project.records[key]
  return null
}

export const getProjectParticipant = (state, key) => {
  // if (key in state.Project.participant)
    return state.admin.project.participant
  // return null
}

export const getChatProject = (state, key) => {
  if (key in state.admin.project.conversation)
    return state.admin.project.conversation[key]
  return null
}

// Async action creator

export const fetchProjectList = (refresh=false) => {
  return (dispatch, getState) => {
    let res = getProjectList(getState())
    
    if (res.isFetching || (res.lastFetched && !refresh))
      return Promise.resolve()

    let user = getState().alpha.user
      
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/project/admin/"

    dispatch(requestProjectList())
    
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
          },
        })
      .then(response => response.json())
      .then(json => dispatch(receiveProjectList(json)))
      .catch(error => dispatch(errorRequestProjectList(error)))
  }
}

export const fetchProject = (key, refresh=false) => {
  return (dispatch, getState) => {
    let res = getProject(getState(), key)
    if (res && (res.isFetching || (res.lastFetched  && !refresh)))
      return Promise.resolve()
      

    let user = getState().alpha.user
    
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/project/admin/" + key + "/"
    
    dispatch(requestProject(key)); 
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",            
          },
        })
      .then(response => response.json())
      .then(json => dispatch(receiveProject(key, json)))
      .catch(error => dispatch(errorRequestProject(key, error)));
  }
}

export const submit = (key, trainerKey) => {
  return (dispatch, getState) => {

    let user = getState().alpha.user

    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/project/admin/" + key + "/submit/"

    let data = { }
    
    dispatch(modifyProject(key))    
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => dispatch(receiveProject(key, json)))
      .catch(error => dispatch(errorModifyProject(key, error)))          
  }
}

export const inviteTrainer = (key, trainerKey) => {
  return (dispatch, getState) => {
    let user = getState().alpha.user

    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/project/admin/" + key + "/invite_trainer/"
    
    let data = { uid: trainerKey }
    
    dispatch(modifyProject(key))    
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => dispatch(receiveProject(key, json)))
      .catch(error => dispatch(errorModifyProject(key, error)))          
  }
}

export const selectProposal = (key, proposalKey) => {
  return (dispatch, getState) => {
    let user = getState().alpha.user

    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/project/admin/" + key + "/select_proposal/"
    
    let data = { uid: proposalKey }
    
    dispatch(modifyProject(key))    
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => dispatch(receiveProject(key, json)))
      .catch(error => dispatch(errorModifyProject(key, error)))          
  }
}

export const acceptProject = (key, onComplete) => {
    return (dispatch, getState) => {
      let user = getState().alpha.user
  
      let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/project/admin/" + key + "/accept/"
      
    //   let data = { uid: proposalKey }
      
      dispatch(modifyProject(key))    
      
      return fetch(url, {
          method: 'POST',
          cache: 'no-cache',
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
          },
        //   body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(json => {
          dispatch(receiveProject(key, json))
          onComplete()
        })
        .catch(error => dispatch(errorModifyProject(key, error)))          
    }
  }

export const rejectProject = (key, data, onComplete) => {
  return (dispatch, getState) => {
    let user = getState().alpha.user

    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/project/admin/" + key + "/cancel/"
    
  //   let data = { uid: proposalKey }
    
    dispatch(modifyProject(key))    
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => {
        dispatch(receiveProject(key, json))
        onComplete()
      })
      .catch(error => dispatch(errorModifyProject(key, error)))          
  }
}

export const joinChatProject = (key, onComplete) => {
  return (dispatch, getState) => {
    let user = getState().alpha.user

    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/project/admin/" + key + "/join_conversation/"
    
  //   let data = { uid: proposalKey }
    
    dispatch(requestChatProject(key))    
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
      //   body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => {
        dispatch(receiveChatProject(key, json))
        onComplete(json.uid)
      })
      .catch(error => dispatch(errorRequestChatProject(key, error)))          
  }
}

export const createOrder = (key, proposalKey) => {
  return (dispatch, getState) => {
    let user = getState().alpha.user

    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/project/admin/" + key + "/create_order/"
    
    let data = { }  
    
    dispatch(modifyProject(key))    
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => dispatch(receiveProject(key, json)))
      .catch(error => dispatch(errorModifyProject(key, error)))          
  }
}

export const updateProject = (key, data) => {
  return (dispatch, getState) => {
    let user = getState().alpha.user

    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/project/admin/" + key + "/"
      
    dispatch(modifyProject(key))    
    
    return fetch(url, {
        method: 'PUT',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => dispatch(receiveProject(key, json)))
      .catch(error => dispatch(errorModifyProject(key, error)))
  }
}

export const createProject = (createKey = "new", data) => {
  return (dispatch, getState) => {
    let user = getState().alpha.user

    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/project/admin/"
    
    // createKey can be anything unique.
    
    dispatch(removeProject(createKey))
    dispatch(modifyProject(createKey))
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => dispatch(receiveProject(createKey, json)))
      .catch(error => dispatch(errorModifyProject(createKey, error)))
  }
}

// Synchronous Action Creators

export const requestProjectList = () => ({
  type: ACTION.requestProjectList,
})

export const receiveProjectList = (json) => {

  let result = {
    count: json.length,
    records: json
  }

//  alert("ProjectList: " + JSON.stringify(result, null, 2))

  return {
    type: ACTION.receiveProjectList,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestProjectList = (error) => ({
  type: ACTION.errorRequestProjectList,
  error
})

//participant
export const requestProjectParticipant = () => ({
  type: ACTION.requestProjectParticipant,
})

export const receiveProjectParticipant = (key, json) => {

  let result = json
  
//  alert("Project Record:" + JSON.stringify(json, null, 2))

  return {
    type: ACTION.receiveProjectParticipant,
    key,
    result: result,
    receivedAt: Date.now()
  }
}

export const errorRequestProjectParticipant = (error) => ({
  type: ACTION.errorRequestProjectParticipant,
  error
})

export const modifyProject = (key) => ({
  type: ACTION.modifyProject,
  key
})

export const requestProject = (key) => ({
  type: ACTION.requestProject,
  key
})

export const receiveProject = (key, json) => {

  let result = json
  
//  alert("Project Record:" + JSON.stringify(json, null, 2))

  return {
    type: ACTION.receiveProject,
    key,
    result: result,
    receivedAt: Date.now()
  }
}

export const removeProject = (key) => ({
  type: ACTION.removeProject,
  key
})

export const errorModifyProject = (key, error) => ({
  type: ACTION.errorModifyProject,
  key,
  error
})

export const errorRequestProject = (key, error) => ({
  type: ACTION.errorRequestProject,
  key,
  error
})

export const requestChatProject = (key) => ({
  type: ACTION.requestChatProject,
  key
})

export const receiveChatProject = (key, json) => {

  let result = json
  
//  alert("Project Record:" + JSON.stringify(json, null, 2))

  return {
    type: ACTION.receiveChatProject,
    key,
    result: result,
    receivedAt: Date.now()
  }
}

export const removeChatProject = (key) => ({
  type: ACTION.removeChatProject,
  key
})

export const errorRequestChatProject = (key, error) => ({
  type: ACTION.errorRequestChatProject,
  key,
  error
})

// Reducers for Conversation

const projectReducer = createReducer(projectInitial, {
  [ACTION.requestProject]: (state, action) => {
      return {
        ...state,
        isFetching: true
      }
    },
  [ACTION.modifyProject]: (state, action) => {
      return {
        ...state,
        isUpdating: true
      }
    },
  [ACTION.receiveProject]: (state, action) => {
  
//      alert(JSON.stringify(action.result, null, 2))

      return {
        ...state,
        isFetching: false,
        lastFetched: action.receivedAt,
        isUpdating: false,
        lastUpdated: action.receivedAt,
        data: action.result
      }
    },
  [ACTION.errorRequestProject]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
  [ACTION.errorModifyProject]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isUpdating: false,
        isError: true
      }
    },
})

const projectChatReducer = createReducer(projectChatInitial, {
  [ACTION.requestChatProject]: (state, action) => {
      return {
        ...state,
        isFetching: true
      }
    },
  [ACTION.receiveChatProject]: (state, action) => {
  
//      alert(JSON.stringify(action.result, null, 2))

      return {
        ...state,
        isFetching: false,
        lastFetched: action.receivedAt,
        isUpdating: false,
        lastUpdated: action.receivedAt,
        data: action.result
      }
    },
  [ACTION.errorRequestChatProject]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
})

const projectListReducer = createReducer(projectListInitial, {
  [ACTION.requestProjectList]: (state, action) => {
      return {  
        ...state,
        isFetching: true
      }
    },
  [ACTION.receiveProjectList]: (state, action) => {
  
      return {
        ...state,
        isFetching: false,
        data: action.result,
        lastFetched: action.receivedAt,
      }        
    },
  [ACTION.errorRequestProjectList]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
})

function projectByKeyUpdater(state, action) {
  let key = action.key
  
  if (action.type == ACTION.removeProject) {
    let newState = { ...state }
    if (key in newState)
      delete newState[key]
    return newState
  }  
  
  return {
    ...state,
    [key]: projectReducer(state[key], action)
  }
}

const projectByKeyReducer = createReducer({}, {
  [ACTION.requestProject]: projectByKeyUpdater,
  [ACTION.modifyProject]: projectByKeyUpdater,
  [ACTION.removeProject]: projectByKeyUpdater,
  [ACTION.receiveProject]: projectByKeyUpdater,
  [ACTION.errorRequestProject]: projectByKeyUpdater,
  [ACTION.errorModifyProject]: projectByKeyUpdater,
})

function projectChatByKeyUpdater(state, action) {
  let key = action.key
  
  if (action.type == ACTION.removeChatProject) {
    let newState = { ...state }
    if (key in newState)
      delete newState[key]
    return newState
  } 

  return {
    ...state,
    [key]: projectChatReducer(state[key], action)
  }
}

const projectChatByKeyReducer = createReducer({}, {
  [ACTION.requestChatProject]: projectChatByKeyUpdater,
  [ACTION.removeChatProject]: projectChatByKeyUpdater,
  [ACTION.receiveChatProject]: projectChatByKeyUpdater,
  [ACTION.errorRequestChatProject]: projectChatByKeyUpdater,
})

// Combine list and records reducer

const projectDataReducer = combineReducers({
  list: projectListReducer,
  records: projectByKeyReducer,
  conversation: projectChatByKeyReducer
});

export default projectDataReducer