import { updateObject, updateItemInArray, createReducer } from './utility'
import ls from 'local-storage'

// Initial Data

const visitorInitial = {
  isInitialized: false,
  isInitializing: false,
  isRegistered: false,
  isRegistering: false,
  data: {
    _goid: null,
    visitor_id: null
  }
}

// Actions

const ACTION = {
  initializing: "initializing",
  registering: "registering",
  receiveInitResult: "receiveInitResult",
  receiveRegisterResult: "receiveRegisterResult",  
  errorInitializing: "errorInitializing",
  errorRegistering: "errorRegistering"
}

// Async Action Creators

export const getVisitor = (state) => {
  if ('visitor' in state.alpha)
    return state.alpha.visitor
  else
    return visitorInitial
}

export const init = () => {
  return (dispatch, getState) => {
    let visitor = getVisitor(getState())
    if (visitor.isInitialized || visitor.isInitializing)
      return Promise.resolve()
      
    let url = process.env.REACT_APP_GOAPP_API_URL + '/visitor/api/init/'
    let data = { 
      _goid: visitor.data._goid,
      _referer: document.referrer,
      _origin: window.location.href
    }

//  alert("init: " + JSON.stringify(data))
//  alert(process.env.REACT_APP_GOAPP_API_KEY)
        
//    alert("Fetching: " + url)

    dispatch(initializing(data));
    
    return fetch(url, { 
      method: 'POST',
      cache: 'no-cache',
      headers: { 
        'Content-Type': 'application/json', 
        'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY 
        },
      body: JSON.stringify(data)
        })
      .then(response => response.json())
      .then(json => dispatch(receiveInitResult(json)))
      .catch(error => dispatch(errorInitializing(error)));
  }
};

export const registerUser = (registerData) => {
  return (dispatch, getState) => {
    let visitor = getVisitor(getState())
    if (visitor.isRegistered || visitor.isRegistering)
      return Promise.resolve()
      
    let url = process.env.REACT_APP_GOAPP_API_URL + '/visitor/api/register_user/'
    
    let data = {
      ...registerData,
      _goid: visitor.data._goid
    }  

    dispatch(registering(data));
    
    return fetch(url, { 
      method: 'POST',
      cache: 'no-cache',
      headers: { 
        'Content-Type': 'application/json', 
        'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY 
        },
      body: JSON.stringify(data)
        })
      .then(response => response.json())
      .then(json => dispatch(receiveRegisterResult(data, json)))
      .catch(error => dispatch(errorRegistering(registerData, error)));
  }
};

export const logEvent = (eventName, eventData) => {
  return (dispatch, getState) => {
    let visitor = getVisitor(getState())
    if (!visitor.isRegistered)
      return Promise.resolve()
  
    let url = process.env.REACT_APP_GOAPP_API_URL + '/visitor/api/log_event/'
  
    let data = {
      event_name: eventName,
      event_data: eventData,
      _goid: visitor.data._goid
    }
    
//    alert("LogEvent: " + JSON.stringify(data, null, 2))

    return fetch(url, { 
      method: 'POST',
      cache: 'no-cache',
      headers: { 
        'Content-Type': 'application/json', 
        'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY 
        },
      body: JSON.stringify(data)
        })
      .then(response => response.json())
      .then(json => {
//      alert("result: " + JSON.stringify(json))
//      ls.set('_goid', json['_goid'])
//      cookie.set("onboarded", true, {path: "/"});
      })
      .catch(error => {
      })
  }
}

// Synchronous Action Creators

export const initializing = () => ({
  type: ACTION.initializing,
});

export const registering = (registerData) => ({
  type: ACTION.registering,
});

export const receiveInitResult = (json) => ({
  type: ACTION.receiveInitResult,
  result: json
});

export const receiveRegisterResult = (registerData, json) => ({
  type: ACTION.receiveRegisterResult,
  registerData,
  result: json
});

export const errorInitializing = (error) => ({
  type: ACTION.errorInitializing,
  error
});

export const errorRegistering = (data, error) => ({
  type: ACTION.errorRegistering,
  data,
  error
});


// Visitor reducer

function _initializing(state, action) {
  return {
    ...state,
    isInitializing: true
  }
}

function _registering(state, action) {
  return {
    ...state,
    isRegistering: true
  }
}

function _errorInitializing(state, action) {
  alert(action.error)
  return {
    ...state,
    isInitializing: false
  }
}

function _errorRegistering(state, action) {
  alert(action.error)
  return {
    ...state,
    isRegistering: false
  }
}

function _receiveInitResult(state, action) {

  let _goid = action.result['_goid']  
  
  // Store _goid on local storage
  ls.set('_goid', _goid)
  
  return {
    ...state,
    isInitializing: false,
    isInitialized: true,
    data: {
      _goid: _goid
    }
  }
}

function _receiveRegisterResult(state, action) {

  return {
    ...state,
    isRegistering: false,
    isRegistered: true,
    data: {
      ...state.data,
      ...action.result,
    }
  }  
}

const visitorReducer = (state=null, action) => {
  if (state == null) {
    // Get userInitial from local storage, but we're still in
    // not authenticated state, authToken is still null.
    // We'll use the info to auto authenticate to auth service
    state = visitorInitial
    let _goid = ls.get('_goid')
    if (_goid && _goid !== undefined && _goid != "undefined")
      state.data._goid = _goid
  }
  
  const handlers = {
    [ACTION.initializing]: _initializing,
    [ACTION.registering]: _registering,
    [ACTION.receiveInitResult]: _receiveInitResult,
    [ACTION.receiveRegisterResult]: _receiveRegisterResult,
    [ACTION.errorInitializing]: _errorInitializing,
    [ACTION.errorRegistering]: _errorRegistering,
  }
  
  if (handlers.hasOwnProperty(action.type))
    return handlers[action.type](state, action)
  else
    return state
}

export default visitorReducer