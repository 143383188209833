import { combineReducers } from 'redux'
import { createReducer } from './utility'

// Data structure

const trainerInitial = {
  isFetching: false,
  isPartial: false,
  isError: false,
  lastFetched: null,
  didInvalidate: false,
  data: { }
}

const trainerIndexInitial = {
  isFetching: false,
  isError: false,
  lastFetched: null,
  path: null,
  data: { }
}

// Actions
const ACTION = {
  fetchTrainerIndex: "fetchTrainerIndex",
  requestTrainerIndex: "requestTrainerIndex",
  receiveTrainerIndex: "receiveTrainerIndex",
  errorRequestTrainerIndex: "errorRequestTrainerIndex",
  invalidateTrainerIndex: "invalidateTrainerIndex",
  fetchTrainer: "fetchTrainer",
  requestTrainer: "requestTrainer",
  receiveTrainer: "receiveTrainer",
  errorRequestTrainer: "errorRequestTrainer",
  invalidateTrainer: "invalidateTrainer",
  requestDownload: "requestDownload"
}


export const indexKeyFor = (path, params) => {
  let key = path

  return key
}

// Async action creator

export const getTrainerIndex = (state, path, params) => {
  let key = indexKeyFor(path, params)
  if (key in state.client.trainer.indexes) {
    return state.client.trainer.indexes[key]
  }
  
  return trainerIndexInitial
}

export const fetchTrainerIndex = (path, params = null, refresh = false, fetchMore = false) => {
  return (dispatch, getState) => {
    let res = getTrainerIndex(getState(), path, params)
    if (res.isFetching || (res.lastFetched && !refresh))
      return Promise.resolve()
      
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainer/api/trainer/search/?key_path=" + path + "&image_size=m"
      
    if (params)
      url += Object.keys(params).map(key =>
        "&" + key + "=" + params[key]).join("")
        
    let user = getState().alpha.user
    let authHeader = {}
    if (user.isLoggedIn) {
      authHeader['Authorization'] = 'jwt ' + user.authToken
      if (user.companyInfo)
        authHeader['X-Company-ID'] = user.companyInfo.uid.toString()
    }
        
    dispatch(requestTrainerIndex(path, params));
    return fetch(url, {
          headers: { 
            'Content-Type': 'application/json',
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            ...authHeader
          },
        })
      .then(response => response.json())
      .then(json => {
        if (fetchMore) {
          let res = getTrainerIndex(getState(), path, null)
          let newItems = res.data.records.concat(json.records)

          let newData = {
            total: json.total,
            categories: json.categories,
            offset: json.offset,
            records: newItems
          }

          dispatch(receiveTrainerIndex(path, params, newData))
        } else {
          dispatch(receiveTrainerIndex(path, params, json))
        }
      })
      .catch(error => dispatch(errorRequestTrainerIndex(path, params, error)));
  }
};

export const getTrainer = (state, recordKey) => {
  if (recordKey in state.client.trainer.records)
      return state.client.trainer.records[recordKey]
  
  return trainerInitial
}


export const fetchTrainer = (recordKey, refresh=false, params=null) => {
  return (dispatch, getState) => {
    let res = getTrainer(getState(), recordKey)
    if (res.isFetching || (res.lastFetched && !res.didInvalidate && !refresh))
      return Promise.resolve()
  
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainer/api/trainer/" + recordKey + "/"
      
    if (params)
      url += Object.keys(params).map(key =>
        "&" + key + "=" + params[key]).join("")
        
    let user = getState().alpha.user  
    let authHeader = {}
    if (user.isLoggedIn) {
      authHeader['Authorization'] = 'jwt ' + user.authToken
      if (user.companyInfo)
        authHeader['X-Company-ID'] = user.companyInfo.uid.toString()
    }      

    dispatch(requestTrainer(recordKey, params)); 
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            ...authHeader
          },
        })
      .then(response => {
//          alert("fetchTrainer response code: " + response.status.toString())
          if (response.status == 200)
              return response.json()
          else if (response.status == 404)
              throw new Error("Not Found")
          else
              throw new Error(response.json())
      })
      .then(json => dispatch(receiveTrainer(recordKey, params, json)))
      .catch(error => dispatch(errorRequestTrainer(recordKey, params, error)))
  }
};

export const downloadTrainerProfile = (trainerId, onComplete) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainer/api/trainer/" + trainerId + "/download/?document_type=trainer_profile"
        
    let user = getState().alpha.user        

    dispatch(requestDownload(trainerId, null)); 
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'Accept' : 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
          },
        })
      .then(response => {
//          alert("fetchTrainer response code: " + response.status.toString())
          if (response.status == 200) {
            onComplete()
            response.blob().then(function(myBlob) {
              var file = new Blob([myBlob], {type: 'application/pdf'})
              var fileURL = URL.createObjectURL(file)
              window.open(fileURL)
            });
          }
          else if (response.status == 404) {
            onComplete()
            throw new Error("Not Found")
          } else {
            onComplete()
            throw new Error(response.json()) 
          }
      })
      // .then(json => {
        
      //   }
      // )
      // .catch(error => dispatch(errorRequestTrainer(trainingClassKey, params, error)))
  }
};

// Synchronous Action Creators for Product Index/Listing

export const invalidateTrainerIndex = (path, params) => ({
  type: ACTION.invalidateProductindex,
  path
});

export const requestTrainerIndex = (path, params) => ({
  type: ACTION.requestTrainerIndex,
  path,
  params
});

export const receiveTrainerIndex = (path, params, json) => {

  let result = {
    path: path,
    count: json.total,
    records: json.records
  }
  
//  alert("receiveTrainerIndex")
//  alert(JSON.stringify(result, null, 2))

  return {
    type: ACTION.receiveTrainerIndex,
    path,
    params,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestTrainerIndex = (recordKey, params, error) => ({
  type: ACTION.errorRequestTrainerIndex,
  recordKey,
  params,
  error
});

export const invalidateTrainer = (recordKey) => ({
  type: ACTION.invalidateTrainer,
  recordKey
});

export const requestTrainer = (recordKey, params) => ({
  type: ACTION.requestTrainer,
  recordKey,
  params
});

export const requestDownload = (recordKey, params) => ({
  type: ACTION.requestDownload,
  recordKey,
  params
});

export const receiveTrainer = (recordKey, params, json) => {

  let result = json

  return {
    type: ACTION.receiveTrainer,
    recordKey,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestTrainer = (recordKey, params, error) => ({
  type: ACTION.errorRequestTrainer,
  recordKey,
  params,
  error
});

// Reducers for Product Index

const trainerIndexReducer = createReducer(trainerIndexInitial, {
  [ACTION.requestTrainerIndex]: (state, action) => {
      return {
        ...state,
        isFetching: true
      }
    },
  [ACTION.receiveTrainerIndex]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        isPartial: false,
        lastFetched: action.receivedAt,
        data: action.result
      }        
    },
  [ACTION.errorRequestTrainerIndex]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
  [ACTION.invalidateTrainerIndex]: (state, action) => {
      if (!state.isFetching)
        return {
          ...state,
          lastFetched: null,
        }
      else
        return state
    },    
})

function trainerIndexByKeyUpdater(state, action) {
  let key = indexKeyFor(action.path, action.params)
  return {
    ...state,
    [key]: trainerIndexReducer(state[key], action)
  }
}

const trainerIndexByKeyReducer = createReducer({}, {
  [ACTION.requestTrainerIndex]: trainerIndexByKeyUpdater,
  [ACTION.receiveTrainerIndex]: trainerIndexByKeyUpdater,
  [ACTION.errorReceiveTrainerIndex]: trainerIndexByKeyUpdater,
  [ACTION.invalidateTrainerIndex]: trainerIndexByKeyUpdater,
})


// Reducers for Product Record

const trainerReducer = createReducer(trainerInitial, {
  [ACTION.requestTrainer]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      }
    },
  [ACTION.receiveTrainer]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        isPartial: false,
        didInvalidate: false,
        lastFetched: action.receivedAt,
        data: action.result
      }        
    },
  [ACTION.errorRequestTrainer]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
  [ACTION.invalidateTrainer]: (state, action) => {
      return {
        ...state,
        didInvalidate: true,
      }
    },    
})

function trainerByKeyUpdater(state, action) {
  let key = action.recordKey
  return {
    ...state,
    [key]: trainerReducer(state[key], action)
  }
}

const trainerByKeyReducer = createReducer({}, {
  [ACTION.requestTrainer]: trainerByKeyUpdater,
  [ACTION.receiveTrainer]: trainerByKeyUpdater,
  [ACTION.errorRequestTrainer]: trainerByKeyUpdater,
  [ACTION.invalidateTrainer]: trainerByKeyUpdater,
})

// Combine all catalog reducer

const trainerDataReducer = combineReducers({
  indexes: trainerIndexByKeyReducer,
  records: trainerByKeyReducer,
});

export default trainerDataReducer