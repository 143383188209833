import { combineReducers } from 'redux'
import { createReducer } from './utility'

// Data structure

const projectInitial = {
  key: null,
  isUpdating: false,
  lastUpdate: null,  
  isFetching: false,
  lastFetched: null,
  isError: false,
  data: { status: null },
}

const projectConversationInitial = {
  key: null,
  isUpdating: false,
  lastUpdate: null,  
  isFetching: false,
  lastFetched: null,
  isError: false,
  data: { },
}

const projectListInitial = {
  isFetching: false,
  lastFetched: null,
  isError: false,
  data: {
    count: 0,
    records: [ ],
  }
}

// Actions
const ACTION = {
  requestProjectList: "requestProjectList",
  receiveProjectList: "receiveProjectList",
  errorRequestProjectList: "errorRequestProjectList",
  
  requestProject: "requestProject",
  receiveProject: "receiveProject",
  modifyProject: "modifyProject",
  removeProject: "removeProject",
  errorModifyProject: "errorModifyProject",
  errorRequestProject: "errorRequestProject",

  requestConversationProject: "requestConversationProject",
  receiveConversationProject: "receiveConversationProject",
  removeConversationProject: "removeConversationProject",
  errorRequestConversationProject: "errorRequestConversationProject",

  requestProviderList: "requestProviderList",
  receiveProviderList: "receiveProviderList",
  errorRequestProviderList: "errorRequestProviderList",
}

export const getProjectList = (state) => {
  return state.client.project.list
}

export const getProject = (state, key) => {
  if (key in state.client.project.records)
    return state.client.project.records[key]
  return null
}

export const getProjectConversation = (state, key) => {
  if (key in state.client.project.conversations)
    return state.client.project.conversations[key]
  return null
}

export const getProviderList = (state, key) => {
  // if (key in state.Project.participant)
    return state.client.project.providers
  // return null
}

// Async action creator

export const fetchProjectList = (refresh=false) => {
  return (dispatch, getState) => {
    let res = getProjectList(getState())
    
    if (res.isFetching || (res.lastFetched && !refresh))
      return Promise.resolve()

    let user = getState().alpha.user
      
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/project/"

    dispatch(requestProjectList())
    
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
          },
        })
      .then(response => response.json())
      .then(json => dispatch(receiveProjectList(json)))
      .catch(error => dispatch(errorRequestProjectList(error)))
  }
}

export const fetchProviderList = (key,refresh=false) => {
  return (dispatch, getState) => {
    let res = getProviderList(getState())
    
    if (res.isFetching || (res.lastFetched && !refresh))
      return Promise.resolve()

    let user = getState().alpha.user
      
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/project/"+key+'/available_providers/'

    dispatch(requestProviderList())
    
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
          },
        })
      .then(response => response.json())
      .then(json => dispatch(receiveProviderList(json)))
      .catch(error => dispatch(errorRequestProviderList(error)))
  }
}

export const fetchProject = (key, refresh=false) => {
  return (dispatch, getState) => {
    let res = getProject(getState(), key)
    if (res && (res.isFetching || (res.lastFetched  && !refresh)))
      return Promise.resolve()
      

    let user = getState().alpha.user
    
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/project/" + key + "/"
    
    dispatch(requestProject(key)); 
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",            
          },
        })
      .then(response => response.json())
      .then(json => dispatch(receiveProject(key, json)))
      .catch(error => dispatch(errorRequestProject(key, error)));
  }
}

export const inviteProvider = (key, data, onComplete) => {
  return (dispatch, getState) => {
    let user = getState().alpha.user

    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/project/" + key + "/invite/"
    
    // let data = { uid: trainerKey }
    
    dispatch(modifyProject(key))    
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => {
        dispatch(receiveProject(key, json))
        onComplete()
      })
      .catch(error => dispatch(errorModifyProject(key, error)))          
  }
}

export const selectProposal = (key, proposalKey, onComplete) => {
  return (dispatch, getState) => {
    let user = getState().alpha.user

    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/project/" + key + "/select_proposal/"
    
    let data = { uid: proposalKey }
    
    dispatch(modifyProject(key))    
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => {
        dispatch(receiveProject(key, json))
        onComplete()
      })
      .catch(error => dispatch(errorModifyProject(key, error)))          
  }
}

export const acceptProject = (key, proposalKey) => {
    return (dispatch, getState) => {
      let user = getState().alpha.user
  
      let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/project/" + key + "/accept/"
      
    //   let data = { uid: proposalKey }
      
      dispatch(modifyProject(key))    
      
      return fetch(url, {
          method: 'POST',
          cache: 'no-cache',
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
          },
        //   body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(json => dispatch(receiveProject(key, json)))
        .catch(error => dispatch(errorModifyProject(key, error)))          
    }
  }

  export const rejectProject = (key, data) => {
    return (dispatch, getState) => {
      let user = getState().alpha.user
  
      let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/project/" + key + "/reject/"
      
    //   let data = { uid: proposalKey }
      
      dispatch(modifyProject(key))    
      
      return fetch(url, {
          method: 'POST',
          cache: 'no-cache',
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
          },
          body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(json => dispatch(receiveProject(key, json)))
        .catch(error => dispatch(errorModifyProject(key, error)))          
    }
  }

  export const cancelProject = (key, data, onComplete) => {
    return (dispatch, getState) => {
      let user = getState().alpha.user
  
      let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/project/" + key + "/cancel/"
      
    //   let data = { uid: proposalKey }
      
      dispatch(modifyProject(key))    
      
      return fetch(url, {
          method: 'POST',
          cache: 'no-cache',
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
          },
          body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(json => {
          dispatch(receiveProject(key, json))
          onComplete()
        })
        .catch(error => dispatch(errorModifyProject(key, error)))          
    }
  }



export const createOrder = (key, proposalKey) => {
  return (dispatch, getState) => {
    let user = getState().alpha.user

    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/project/" + key + "/create_order/"
    
    let data = { }  
    
    dispatch(modifyProject(key))    
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => dispatch(receiveProject(key, json)))
      .catch(error => dispatch(errorModifyProject(key, error)))          
  }
}

export const updateProject = (key, data, onComplete) => {
  return (dispatch, getState) => {
    let user = getState().alpha.user

    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/project/" + key + "/"
      
    dispatch(modifyProject(key))    
    
    return fetch(url, {
        method: 'PUT',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => {
        dispatch(receiveProject(key, json))
        onComplete()
      })
      .catch(error => dispatch(errorModifyProject(key, error)))
  }
}

export const submitProject = (key, data, onComplete) => {
    return (dispatch, getState) => {
      let user = getState().alpha.user
  
      let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/project/" + key + "/submit/"
        
      dispatch(modifyProject(key))    
      
      return fetch(url, {
          method: 'POST',
          cache: 'no-cache',
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
          },
          body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(json => {
          dispatch(receiveProject(key, json))
          onComplete()
        })
        .catch(error => dispatch(errorModifyProject(key, error)))
    }
  }

  export const deleteProject = (key, onComplete) => {
    return (dispatch, getState) => {
      let user = getState().alpha.user
  
      let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/project/" + key +"/"
      
      return fetch(url, {
          method: 'DELETE',
          cache: 'no-cache',
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : ""
          },
        })
        .then(response => {
            if(response.status === 204){
                onComplete()
            }else{
                alert("ERROR")
            }
        })
        .catch(error => error)
    }
  }

export const createProject = (createKey = "new", data, onComplete) => {
  return (dispatch, getState) => {
    let user = getState().alpha.user

    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/project/?submit=1"
    
    // createKey can be anything unique.
    
    dispatch(removeProject(createKey))
    dispatch(modifyProject(createKey))
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => {
        dispatch(receiveProject(createKey, json))
        onComplete(json.uid)
      })
      .catch(error => dispatch(errorModifyProject(createKey, error)))
  }
}

export const joinConversationProject = (key, data, onComplete) => {
  return (dispatch, getState) => {
    let user = getState().alpha.user

    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/project/" + key + "/join_conversation/"
    
    dispatch(requestConversationProject(key))    
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => {
        dispatch(receiveConversationProject(key, json))
        onComplete(json.uid)
      })
      .catch(error => dispatch(errorRequestConversationProject(key, error)))          
  }
}

export const saveDraftProject = (createKey = "new", data, onComplete) => {
  return (dispatch, getState) => {
    let user = getState().alpha.user

    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/project/"
    
    // createKey can be anything unique.
    
    dispatch(removeProject(createKey))
    dispatch(modifyProject(createKey))
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => {
        dispatch(receiveProject(createKey, json))
        onComplete(json.uid)
      })
      .catch(error => dispatch(errorModifyProject(createKey, error)))
  }
}

// Synchronous Action Creators

export const requestProjectList = () => ({
  type: ACTION.requestProjectList,
})

export const receiveProjectList = (json) => {

  let result = {
    count: json.length,
    records: json
  }

//  alert("ProjectList: " + JSON.stringify(result, null, 2))

  return {
    type: ACTION.receiveProjectList,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestProjectList = (error) => ({
  type: ACTION.errorRequestProjectList,
  error
})

//participant
export const requestProviderList = () => ({
  type: ACTION.requestProviderList,
})

export const receiveProviderList = (json) => {

  let result = json
  
//  alert("Project Record:" + JSON.stringify(json, null, 2))

  return {
    type: ACTION.receiveProviderList,
    // key,
    result: result,
    receivedAt: Date.now()
  }
}

export const errorRequestProviderList = (error) => ({
  type: ACTION.errorRequestProviderList,
  error
})

export const modifyProject = (key) => ({
  type: ACTION.modifyProject,
  key
})

export const requestProject = (key) => ({
  type: ACTION.requestProject,
  key
})

export const receiveProject = (key, json) => {

  let result = json
  
//  alert("Project Record:" + JSON.stringify(json, null, 2))

  return {
    type: ACTION.receiveProject,
    key,
    result: result,
    receivedAt: Date.now()
  }
}

export const removeProject = (key) => ({
  type: ACTION.removeProject,
  key
})

export const errorModifyProject = (key, error) => ({
  type: ACTION.errorModifyProject,
  key,
  error
})

export const errorRequestProject = (key, error) => ({
  type: ACTION.errorRequestProject,
  key,
  error
})

export const requestConversationProject = (key) => ({
  type: ACTION.requestConversationProject,
  key
})

export const receiveConversationProject = (key, json) => {

  let result = json
  
//  alert("Project Record:" + JSON.stringify(json, null, 2))

  return {
    type: ACTION.receiveConversationProject,
    key,
    result: result,
    receivedAt: Date.now()
  }
}

export const errorRequestConversationProject = (key, error) => ({
  type: ACTION.errorRequestConversationProject,
  key,
  error
})

export const removeConversationProject = (key) => ({
  type: ACTION.removeConversationProject,
  key
})

// Reducers for Conversation

const projectReducer = createReducer(projectInitial, {
  [ACTION.requestProject]: (state, action) => {
      return {
        ...state,
        isFetching: true
      }
    },
  [ACTION.modifyProject]: (state, action) => {
      return {
        ...state,
        isUpdating: true
      }
    },
  [ACTION.receiveProject]: (state, action) => {
  
//      alert(JSON.stringify(action.result, null, 2))

      return {
        ...state,
        isFetching: false,
        lastFetched: action.receivedAt,
        isUpdating: false,
        lastUpdated: action.receivedAt,
        data: action.result
      }
    },
  [ACTION.errorRequestProject]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
  [ACTION.errorModifyProject]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isUpdating: false,
        isError: true
      }
    },
})

const projectConversationReducer = createReducer(projectConversationInitial, {
  [ACTION.requestConversationProject]: (state, action) => {
      return {
        ...state,
        isFetching: true
      }
    },
  [ACTION.receiveConversationProject]: (state, action) => {

      return {
        ...state,
        isFetching: false,
        lastFetched: action.receivedAt,
        isUpdating: false,
        lastUpdated: action.receivedAt,
        data: action.result
      }
    },
  [ACTION.errorConversationRequestProject]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
})

const projectListReducer = createReducer(projectListInitial, {
  [ACTION.requestProjectList]: (state, action) => {
      return {  
        ...state,
        isFetching: true
      }
    },
  [ACTION.receiveProjectList]: (state, action) => {
  
      return {
        ...state,
        isFetching: false,
        data: action.result,
        lastFetched: action.receivedAt,
      }        
    },
  [ACTION.errorRequestProjectList]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
})

const providerListReducer = createReducer(projectListInitial, {
  [ACTION.requestProviderList]: (state, action) => {
      return {  
        ...state,
        isFetching: true
      }
    },
  [ACTION.receiveProviderList]: (state, action) => {
  
      return {
        ...state,
        isFetching: false,
        data: action.result,
        lastFetched: action.receivedAt,
      }        
    },
  [ACTION.errorRequestProviderList]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
})

function projectByKeyUpdater(state, action) {
  let key = action.key
  
  if (action.type == ACTION.removeProject) {
    let newState = { ...state }
    if (key in newState)
      delete newState[key]
    return newState
  }  
  
  return {
    ...state,
    [key]: projectReducer(state[key], action)
  }
}

const projectByKeyReducer = createReducer({}, {
  [ACTION.requestProject]: projectByKeyUpdater,
  [ACTION.modifyProject]: projectByKeyUpdater,
  [ACTION.removeProject]: projectByKeyUpdater,
  [ACTION.receiveProject]: projectByKeyUpdater,
  [ACTION.errorRequestProject]: projectByKeyUpdater,
  [ACTION.errorModifyProject]: projectByKeyUpdater,
})

function projectConversationByKeyUpdater(state, action) {
  let key = action.key
  
  if (action.type == ACTION.removeConversationProject) {
    let newState = { ...state }
    if (key in newState)
      delete newState[key]
    return newState
  }  
  
  return {
    ...state,
    [key]: projectConversationReducer(state[key], action)
  }
}

const projectConversationByKeyReducer = createReducer({}, {
  [ACTION.requestConversationProject]: projectConversationByKeyUpdater,
  [ACTION.removeConversationProject]: projectConversationByKeyUpdater,
  [ACTION.receiveConversationProject]: projectConversationByKeyUpdater,
  [ACTION.errorRequestConversationProject]: projectConversationByKeyUpdater,
})

// Combine list and records reducer

const projectDataReducer = combineReducers({
  list: projectListReducer,
  records: projectByKeyReducer,
  providers: providerListReducer,
  conversations: projectConversationByKeyReducer
});

export default projectDataReducer