import { combineReducers } from 'redux'
import { createReducer } from './utility'

// Data structure

const publicOrderInitial = {
  key: null,
  isUpdating: false,
  lastUpdate: null,  
  isFetching: false,
  lastFetched: null,
  isError: false,
  data: { status: null },
}

const trainerInitial = {
  isFetching: false,
  isPartial: false,
  isError: false,
  lastFetched: null,
  didInvalidate: false,
  data: { }
}

const publicOrderListInitial = {
  isFetching: false,
  lastFetched: null,
  isError: false,
  data: {
    count: 0,
    records: [ ],
  }
}

// Actions
const ACTION = {
  requestPublicOrderList: "requestPublicOrderList",
  receivePublicOrderList: "receivePublicOrderList",
  errorRequestPublicOrderList: "errorRequestPublicOrderList",
  
  requestPublicOrder: "requestPublicOrder",
  receivePublicOrder: "receivePublicOrder",
  modifyPublicOrder: "modifyPublicOrder",
  removePublicOrder: "removePublicOrder",
  errorModifyPublicOrder: "errorModifyPublicOrder",
  errorRequestPublicOrder: "errorRequestPublicOrder",

  fetchTrainerIndex: "fetchTrainerIndex",
  requestTrainerIndex: "requestTrainerIndex",
  receiveTrainerIndex: "receiveTrainerIndex",
  errorRequestTrainerIndex: "errorRequestTrainerIndex",
  invalidateTrainerIndex: "invalidateTrainerIndex",
  fetchTrainer: "fetchTrainer",
  requestTrainer: "requestTrainer",
  requestDownload: "requestDownload",
  requestState: "requestState",
  receiveTrainer: "receiveTrainer",
  receiveState: "receiveState",
  receiveCity: "receiveCity",
  errorRequestTrainer: "errorRequestTrainer",
  errorRequestState: "errorRequestState",
  errorRequestCity: "errorRequestCity",
  errorRequestDownload: "errorRequestDownload",
  invalidateTrainer: "invalidateTrainer",
}

export const getPublicOrderList = (state) => {
  return state.client.publicOrder.list
}

export const getPublicOrder = (state, key) => {
  if (key in state.client.publicOrder.records)
    return state.client.publicOrder.records[key]
  return null
}

export const getTrainer = (state, recordKey) => {
  if (recordKey in state.trainer.records)
      return state.trainer.records[recordKey]
  
  return trainerInitial
}

export const requestTrainer = (recordKey, params) => ({
  type: ACTION.requestTrainer,
  recordKey,
  params
});

export const requestDownload = (recordKey, params) => ({
  type: ACTION.requestDownload,
  recordKey,
  params
});

export const requestState = (recordKey, params) => ({
  type: ACTION.requestState,
  recordKey,
  params
});

export const receiveTrainer = (recordKey, params, json) => {

  let result = json

  return {
    type: ACTION.receiveTrainer,
    recordKey,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestTrainer = (recordKey, params, error) => ({
  type: ACTION.errorRequestTrainer,
  recordKey,
  params,
  error
});

export const receiveState = (recordKey, params, json) => {

  let result = json

  return {
    type: ACTION.receiveState,
    recordKey,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestState = (recordKey, params, error) => ({
  type: ACTION.errorRequestState,
  recordKey,
  params,
  error
});

// Async action creator

export const fetchPublicOrderList = (refresh=false) => {
  return (dispatch, getState) => {
    let res = getPublicOrderList(getState())
    
    if (res.isFetching || (res.lastFetched && !refresh))
      return Promise.resolve()
      
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/order/"

    let user = getState().alpha.user
    let authHeader = {}
    if (user.isLoggedIn) {
      authHeader['Authorization'] = 'jwt ' + user.authToken
      if (user.companyInfo)
        authHeader['X-Company-ID'] = user.companyInfo.uid.toString()
    }

    dispatch(requestPublicOrderList())
    
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            ...authHeader
          },
        })
      .then(response => response.json())
      .then(json => dispatch(receivePublicOrderList(json)))
      .catch(error => dispatch(errorRequestPublicOrderList(error)))
  }
}

export const fetchPublicOrder = (key, refresh=false) => {
  return (dispatch, getState) => {
    let res = getPublicOrder(getState(), key)
    // if (res && (res.isFetching || (res.lastFetched  && !refresh)))
    //   return Promise.resolve()
      
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/order/" + key + "/"
      
    let user = getState().alpha.user
    let authHeader = {}
    if (user.isLoggedIn) {
      authHeader['Authorization'] = 'jwt ' + user.authToken
      if (user.companyInfo)
        authHeader['X-Company-ID'] = user.companyInfo.uid.toString()
    }
    
    dispatch(requestPublicOrder(key)); 
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            ...authHeader
          },
        })
      .then(response => response.json())
      .then(json => dispatch(receivePublicOrder(key, json)))
      .catch(error => dispatch(errorRequestPublicOrder(key, error)));
  }
}

export const submit = (key, trainerKey) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/order/" + key + "/submit/"

    let user = getState().alpha.user
    
    let data = { }
    
    dispatch(modifyPublicOrder(key))    
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => dispatch(receivePublicOrder(key, json)))
      .catch(error => dispatch(errorModifyPublicOrder(key, error)))          
  }
}

export const inviteTrainer = (key, trainerKey) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/order/" + key + "/invite_trainer/"

    let user = getState().alpha.user
    
    let data = { uid: trainerKey }
    
    dispatch(modifyPublicOrder(key))    
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => dispatch(receivePublicOrder(key, json)))
      .catch(error => dispatch(errorModifyPublicOrder(key, error)))          
  }
}

export const selectProposal = (key, proposalKey) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/order/" + key + "/select_proposal/"

    let user = getState().alpha.user
    
    let data = { uid: proposalKey }
    
    dispatch(modifyPublicOrder(key))    
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => dispatch(receivePublicOrder(key, json)))
      .catch(error => dispatch(errorModifyPublicOrder(key, error)))          
  }
}

export const createOrder = (key, proposalKey) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/order/" + key + "/create_order/"

    let user = getState().alpha.user
    
    let data = { }  
    
    dispatch(modifyPublicOrder(key))    
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => dispatch(receivePublicOrder(key, json)))
      .catch(error => dispatch(errorModifyPublicOrder(key, error)))          
  }
}

export const cancelPublicOrder = (key, proposalKey) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/order/" + key + "/cancel/"

    let user = getState().alpha.user
    
    let data = { }  
    
    dispatch(modifyPublicOrder(key))    
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => dispatch(receivePublicOrder(key, json)))
      .catch(error => dispatch(errorModifyPublicOrder(key, error)))          
  }
}

export const updatePublicOrder = (key, data) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/order/" + key + "/"

    let user = getState().alpha.user
      
    dispatch(modifyPublicOrder(key))    
    
    return fetch(url, {
        method: 'PUT',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => dispatch(receivePublicOrder(key, json)))
      .catch(error => dispatch(errorModifyPublicOrder(key, error)))
  }
}

export const fetchAvailableTrainer = (publicOrderKey, refresh=false, params=null) => {
  return (dispatch, getState) => {
    let res = getTrainer(getState(), publicOrderKey)
    if (res.isFetching || (res.lastFetched && !res.didInvalidate && !refresh))
      return Promise.resolve()
  
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/order/" + publicOrderKey + "/available_trainers/"
      
    if (params)
      url += Object.keys(params).map(key =>
        "&" + key + "=" + params[key]).join("")
        
    let user = getState().alpha.user        

    dispatch(requestTrainer(publicOrderKey, params)); 
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
          },
        })
      .then(response => {
//          alert("fetchTrainer response code: " + response.status.toString())
          if (response.status == 200)
              return response.json()
          else if (response.status == 404)
              throw new Error("Not Found")
          else
              throw new Error(response.json())
      })
      .then(json => dispatch(receiveTrainer(publicOrderKey, params, json)))
      .catch(error => dispatch(errorRequestTrainer(publicOrderKey, params, error)))
  }
};

export const downloadProposal = (publicOrderKey, refresh=false, params=null) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/order/" + publicOrderKey + "/download/?document_type=proposal"
        
    let user = getState().alpha.user        

    dispatch(requestDownload(publicOrderKey, params)); 
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'Accept' : 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
          },
        })
      .then(response => {
//          alert("fetchTrainer response code: " + response.status.toString())
          if (response.status == 200){
            response.blob().then(function(myBlob) {
              var file = new Blob([myBlob], {type: 'application/pdf'})
              var fileURL = URL.createObjectURL(file)
              window.open(fileURL)
            });
          }
          else if (response.status == 404)
              throw new Error("Not Found")
          else
              throw new Error(response.json())
      })
      // .then(json => {
        
      //   }
      // )
      // .catch(error => dispatch(errorRequestTrainer(publicOrderKey, params, error)))
  }
};

export const downloadProposalByProposalId = (publicOrderKey, proposalId, refresh=false, params=null) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/order/" + publicOrderKey + "/download/?document_type=proposal&proposal="+proposalId
        
    let user = getState().alpha.user        

    dispatch(requestDownload(publicOrderKey, params)); 
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'Accept' : 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
          },
        })
      .then(response => {
//          alert("fetchTrainer response code: " + response.status.toString())
          if (response.status == 200){
            response.blob().then(function(myBlob) {
              var file = new Blob([myBlob], {type: 'application/pdf'})
              var fileURL = URL.createObjectURL(file)
              window.open(fileURL)
            });
          }
          else if (response.status == 404)
              throw new Error("Not Found")
          else
              throw new Error(response.json())
      })
      // .then(json => {
        
      //   }
      // )
      // .catch(error => dispatch(errorRequestTrainer(publicOrderKey, params, error)))
  }
};

export const downloadLetter = (publicOrderKey, refresh=false, params=null) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/order/" + publicOrderKey + "/download/?document_type=assignment_letter"
        
    let user = getState().alpha.user        

    dispatch(requestDownload(publicOrderKey, params)); 
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
          },
        })
      .then(response => {
//          alert("fetchTrainer response code: " + response.status.toString())
          if (response.status == 200)
            response.blob().then(function(myBlob) {
              var file = new Blob([myBlob], {type: 'application/pdf'})
              var fileURL = URL.createObjectURL(file)
              window.open(fileURL)
            });
          else if (response.status == 404)
              throw new Error("Not Found")
          else
              throw new Error(response.json())
      })
      // .then(json => dispatch(receiveTrainer(publicOrderKey, params, json)))
      // .catch(error => dispatch(errorRequestTrainer(publicOrderKey, params, error)))
  }
};

export const downloadReport = (publicOrderKey, reportUid, refresh=false, params=null) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/order/" + publicOrderKey + "/download/?document_type=report&report="+reportUid
        
    let user = getState().alpha.user        

    dispatch(requestDownload(publicOrderKey, params)); 
    return fetch(url, { 
          headers: { 
            'Content-Type': 'application/json',
            'Accept' : 'application/json',
            'Authorization': 'jwt ' + user.authToken,
            'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
            'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
          },
        })
      .then(response => {
//          alert("fetchTrainer response code: " + response.status.toString())
          if (response.status == 200){
            response.blob().then(function(myBlob) {
              var file = new Blob([myBlob], {type: 'application/pdf'})
              var fileURL = URL.createObjectURL(file)
              window.open(fileURL)
            });
          }
          else if (response.status == 404)
              throw new Error("Not Found")
          else
              throw new Error(response.json())
      })
      // .then(json => {
        
      //   }
      // )
      // .catch(error => dispatch(errorRequestTrainer(publicOrderKey, params, error)))
  }
};

export const createPublicOrder = (createKey, data) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainingclass/api/order/?submit=1"

    let user = getState().alpha.user
    
    // createKey can be anything unique.
    
    dispatch(removePublicOrder(createKey))
    dispatch(modifyPublicOrder(createKey))
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'jwt ' + user.authToken,
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          'X-Company-ID': user.companyInfo ? user.companyInfo.uid.toString() : "",
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => dispatch(receivePublicOrder(createKey, json)))
      .catch(error => dispatch(errorModifyPublicOrder(createKey, error)))
  }
}


// Synchronous Action Creators

export const requestPublicOrderList = () => ({
  type: ACTION.requestPublicOrderList,
})

export const receivePublicOrderList = (json) => {

  let result = {
    count: json.length,
    records: json
  }

//  alert("publicOrderList: " + JSON.stringify(result, null, 2))

  return {
    type: ACTION.receivePublicOrderList,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestPublicOrderList = (error) => ({
  type: ACTION.errorRequestPublicOrderList,
  error
})

export const modifyPublicOrder = (key) => ({
  type: ACTION.modifyPublicOrder,
  key
})

export const requestPublicOrder = (key) => ({
  type: ACTION.requestPublicOrder,
  key
})

export const receivePublicOrder = (key, json) => {

  let result = json
  
//  alert("PublicOrder Record:" + JSON.stringify(json, null, 2))

  return {
    type: ACTION.receivePublicOrder,
    key,
    result: result,
    receivedAt: Date.now()
  }
}

export const removePublicOrder = (key) => ({
  type: ACTION.removePublicOrder,
  key
})

export const errorModifyPublicOrder = (key, error) => ({
  type: ACTION.errorModifyPublicOrder,
  key,
  error
})

export const errorRequestPublicOrder = (key, error) => ({
  type: ACTION.errorRequestPublicOrder,
  key,
  error
})

// Reducers for Conversation

const publicOrderReducer = createReducer(publicOrderInitial, {
  [ACTION.requestPublicOrder]: (state, action) => {
      return {
        ...state,
        isFetching: true
      }
    },
  [ACTION.modifyPublicOrder]: (state, action) => {
      return {
        ...state,
        isUpdating: true
      }
    },
  [ACTION.receivePublicOrder]: (state, action) => {
  
//      alert(JSON.stringify(action.result, null, 2))

      return {
        ...state,
        isFetching: false,
        lastFetched: action.receivedAt,
        isUpdating: false,
        lastUpdated: action.receivedAt,
        data: action.result
      }
    },
  [ACTION.errorRequestPublicOrder]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
  [ACTION.errorModifyPublicOrder]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isUpdating: false,
        isError: true
      }
    },
})

const publicOrderListReducer = createReducer(publicOrderListInitial, {
  [ACTION.requestPublicOrderList]: (state, action) => {
      return {  
        ...state,
        isFetching: true
      }
    },
  [ACTION.receivePublicOrderList]: (state, action) => {
  
      return {
        ...state,
        isFetching: false,
        data: action.result,
        lastFetched: action.receivedAt,
      }        
    },
  [ACTION.errorRequestPublicOrderList]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
})

function publicOrderByKeyUpdater(state, action) {
  let key = action.key
  
  if (action.type == ACTION.removePublicOrder) {
    let newState = { ...state }
    if (key in newState)
      delete newState[key]
    return newState
  }  
  
  return {
    ...state,
    [key]: publicOrderReducer(state[key], action)
  }
}

const publicOrderByKeyReducer = createReducer({}, {
  [ACTION.requestPublicOrder]: publicOrderByKeyUpdater,
  [ACTION.modifyPublicOrder]: publicOrderByKeyUpdater,
  [ACTION.removePublicOrder]: publicOrderByKeyUpdater,
  [ACTION.receivePublicOrder]: publicOrderByKeyUpdater,
  [ACTION.errorRequestPublicOrder]: publicOrderByKeyUpdater,
  [ACTION.errorModifyPublicOrder]: publicOrderByKeyUpdater,
})

// Combine list and records reducer

const publicOrderDataReducer = combineReducers({
  list: publicOrderListReducer,
  records: publicOrderByKeyReducer,
});

export default publicOrderDataReducer